import useResponsive from 'hooks/useResponsive';
import { Oval } from 'react-loader-spinner';
import { styled } from '@mui/material/styles';

import React from 'react';
import COLORS from 'color';

const Spinner = styled(Oval)`
  margin: 0 auto;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100% !important;
  }
`;
const ContainerSpinner = styled('div')(`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`);

const LoadingSpinner: React.FC<any> = ({
  style = {},
  shouldBeCenter = false,
}) => {
  const { theme } = useResponsive();
  if (shouldBeCenter) {
    style = {
      ...style,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }
  return (
    <ContainerSpinner style={style}>
      <Spinner
        theme={theme}
        color={COLORS.BLUE_SILKKE}
        secondaryColor={COLORS.BLUE_SILKKE_DARK}
        height={50}
        strokeWidth={5}
        width={50}
      />
    </ContainerSpinner>
  );
};

export default LoadingSpinner;
