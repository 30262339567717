import COLORS from 'color';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { ComponentProps } from 'type/ComponentProps';
import { Link } from 'react-router-dom';

export const Container = styled.div<ComponentProps>`
  height: 100%;
  width: 100%;
`;

export const Main = styled.div<ComponentProps>`
  background: ${COLORS.GAINSBORO};
  height: 74.5%;
  max-height: 74.5%;
`;

export const Title = styled.div<ComponentProps>`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: ${({ size }) => (size?.isMobile ? '10%' : '3%')} 0;
  color: ${COLORS.GREY};
  font-family: "Urbanist", sans-serif;
`;

export const CalendarComp = styled(Calendar)<ComponentProps>`
  margin: ${({ size }) => (size?.isMobile ? '5%' : '0%')} auto;
  width: ${({ size }) => (size?.isMobile ? '90%' : '50%')};
  height: ${({ size }) => (size?.isMobile ? '50%' : 'auto')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
`;

export const ButtonLink = styled(Link)<ComponentProps>`
  background: ${COLORS.BLUE_SILKKE};
  color: white;
  font-size: 1.2rem;
  display: block;
  margin: ${({ size }) => (size?.isMobile ? '10%' : '5%')} auto;
  border: none;
  width: 30%;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  &:hover {
    background: ${COLORS.BLUE_SILKKE_DARK};
  }
  transition: background 0.2s ease;
`;
