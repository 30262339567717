import { request } from './request';

export const getAvailableCapsuleLocations = async () => {
  const cl = await request(true).get('/capsule-location/available');
  return cl;
};

export const getCapsuleLocationDetails = async (idCapsuleLocation: string) => {
  const fastpass = new URLSearchParams(window.location.search).get('fastpass');
  const params = fastpass ? 'fastpass=true' : '';
  const cl = await request(true).get(
    `/capsule-location?idCapsuleLocation=${idCapsuleLocation}&${params}`,
  );
  return cl;
};
