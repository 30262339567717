type Error = {
  [key: string]: string;
};

const error: Error = {
  userKeyNotFound:
    "User wasn't found with the key provided. Check your mail and try again.",
  unauthorized:
    'Content not authorized. Please log in again or contact the administrator if the problem persists',
  relog: 'You have been logged out. Please log in again.',
};

export default error;
