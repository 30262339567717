export default {
  'settings.title': 'Compte',
  'settings.accountDetails.title': 'informations',
  'settings.accountDetails.edit': 'Modifier',
  'settings.accountDetails.save': 'Enregistrer',
  'settings.accountDetails.oldPassword': 'Ancien mot de passe',
  'settings.accountDetails.editPasswordTitle': 'mot de passe',
  'settings.accountDetails.badOldPassword': 'Ancien mot de passe incorrect',
  'settings.accountDetails.language.title': 'votre langue',
};
