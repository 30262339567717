import React from 'react';
import { useTranslation } from 'react-i18next';

import useResponsive from 'hooks/useResponsive';
import LoadingSpinner from 'component/LoadingSpinner';
import * as modal from './ConfirmModalStyle';

type ModalProps = {
  title: string;
  body: string | React.ReactNode;
  onConfirm: (...args: any[]) => void | undefined;
  onCancel: (...args: any[]) => void | undefined;
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
};

const ConfirmModal: React.FC<ModalProps> = ({
  loading = false,
  title,
  body,
  onConfirm,
  onCancel,
}) => {
  const screenSize = useResponsive();
  const { t } = useTranslation();

  return (
    <modal.ModalOverlay>
      <modal.ModalContainer>
        <modal.ModalContent size={screenSize}>
          <modal.ModalHeader>{title}</modal.ModalHeader>
          <modal.ModalBody>{body}</modal.ModalBody>
          <modal.ModalFooter>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <modal.ModalButton
                  size={screenSize}
                  type="button"
                  onClick={onCancel}
                >
                  {t('modal.cancel')}
                </modal.ModalButton>
                <modal.ModalButton
                  size={screenSize}
                  type="button"
                  onClick={onConfirm}
                >
                  {t('modal.confirm')}
                </modal.ModalButton>
              </>
            )}
          </modal.ModalFooter>
        </modal.ModalContent>
      </modal.ModalContainer>
    </modal.ModalOverlay>
  );
};

export default ConfirmModal;
