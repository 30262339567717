import { Grid } from '@mui/material';
import Header from 'component/global/Header/Header';
import useResponsive from 'hooks/useResponsive';
import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as style from './SettingsStyle';

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  useEffect(() => {
    document.title = t('title.settings');
  }, []);
  return (
    <style.Container>
      <Header title={t('settings.title')} subTitle="" />
      {/*       <Grid
        container
        style={{
          width: '100%',
          margin: '2% 0 auto',
        }}
      >
        <Grid item xs={4} xl={4}>
          <style.NavItem
            style={screenSize.isDesktop ? { marginRight: '-1%' } : {}}
            size={screenSize}
            to="/account/details"
          >
            account details
          </style.NavItem>
        </Grid>
        <Grid item xs={4} xl={4}>
          <style.NavItem size={screenSize} to="/account/credit-cards">credit cards</style.NavItem>
        </Grid>
        <Grid item xs={4} xl={4}>
          <style.NavItem
            style={screenSize.isDesktop ? { marginLeft: '-1%' } : {}}
            size={screenSize}
            to="/account/order"
          >
            orders
          </style.NavItem>
        </Grid>
      </Grid> */}

      <style.ViewSettings>
        <Outlet />
      </style.ViewSettings>
    </style.Container>
  );
};

export default Settings;
