/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { Component } from 'type/Component';
import { useLocation } from 'react-router-dom';
import useResponsive from 'hooks/useResponsive';
import { ScreenSize } from 'type/ScreenSize';
import { logout } from 'providers/auth';
import { useTranslation } from 'react-i18next';
import {
  SubListComponent,
  SubListItem,
  Overlay,
  BurgerIcon,
  Line,
  SubMenu,
  List,
  SubListLink,
  Delimiter,
} from './styled';
import navItems from './navItems';

export const SubList: any = ({ children, isLast }: any) => {
  const screenSize = useResponsive();
  return isLast ? (
    <SubListComponent>
      <SubListItem>{children}</SubListItem>
    </SubListComponent>
  ) : (
    <SubListComponent>
      <SubListItem>{children}</SubListItem>
    </SubListComponent>
  );
};
const Icon = ({
  isOpen,
  openSetter,
  size,
}: {
  isOpen: boolean;
  openSetter: any;
  size: ScreenSize;
}) => (
  <BurgerIcon size={size} isOpen={isOpen} onClick={openSetter}>
    <div />
    <div />
    <div />
  </BurgerIcon>
);
const isActive = (path: string) => {
  const currentPath = window.location.pathname;
  return currentPath === path;
};
const MobileMenu: Component = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [pathname, setPathname] = useState('');
  const location = useLocation();
  const screenSize = useResponsive();
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  const forbiddenPaths = [
    'login',
    'register',
    'logout',
    'terms',
    'complete-registration',
    'create',
  ];

  const logoutHandler = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    await logout();
    window.location.href = '/login';
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return forbiddenPaths.some((path) => pathname.includes(path)) ? null : (
    <div style={{ position: 'relative' }}>
      <Overlay
        isOpen={isOpen}
        size={screenSize}
        onClick={() => setIsOpen(false)}
      />
      <Icon
        size={screenSize}
        isOpen={isOpen}
        openSetter={() => setIsOpen(!isOpen)}
      />
      <List isOpen={isOpen} size={screenSize}>
        <Line onClick={() => setIsOpen(false)} />
        <SubMenu>
          {navItems.map((section: Array<any>, index) => {
            const { length } = navItems;
            const isLast = index === length - 1;

            return (
              <SubList
                key={Math.random().toString(36).substring(2, 9)}
                // no bottom separator for last group item
                isLast={isLast}
              >
                {section.map((item: any, index: number) => {
                  const isSecond = index % 2 === 1;

                  return (
                    <SubListLink
                      size={screenSize}
                      key={t(item.name)}
                      to={item.path}
                      variant={item.variant}
                      active={isActive(item.path).toString()}
                      dangerouslySetInnerHTML={{ __html: t(item.name) || '' }}
                      onClick={(e) => {
                        if (item.path === '/logout') {
                          logoutHandler(e);
                        }
                        closeMenu();
                      }}
                    />
                  );
                })}
              </SubList>
            );
          })}
        </SubMenu>
      </List>
    </div>
  );
};

export default MobileMenu;
