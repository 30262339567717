/*   */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';
import Map from 'component/Map/Map';

export const Booking = styled.div`
  width: 100%;
  height: 74.5%;
`;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${COLORS.GAINSBORO};
`;

export const BookingListType = styled.div<ComponentProps>`
  text-align: center;
  padding-top: ${({ size }) => (size?.isMobile ? '10%' : '3%')};
  & > button:nth-child(2) {
    margin-left: 10px;
  }
`;
export const BookingListTypeButton = styled.button<
ComponentProps & { selected?: boolean }
>`
  width: auto;
  padding: 0 2rem;
  background: ${({ selected }) => (!selected ? COLORS.GAINSBORO : COLORS.BLUE_SILKKE)};
  color: ${({ selected }) => (!selected ? COLORS.BLUE_SILKKE : 'white')};
  border: 1px solid ${COLORS.BLUE_SILKKE};
  height: 2.5rem;
  font-size: 1.15rem;

  cursor: pointer;
`;

export const BookingLocation = styled.div<ComponentProps>`
  overflow: scroll;
  max-height: 75%;
`;

export const BookingListItem = styled(Link)<ComponentProps>`
  border: none;
  display: flex;
  -webkit-box-shadow: rgb(0, 0, 0) 7px 11px 11px -12px;
  box-shadow: rgb(0, 0, 0) 7px 11px 11px -12px;
  justify-content: center;
  width: ${({ size }) => (size?.isMobile ? '80%' : '50%')};
  margin: 3% auto;
  &:hover {
    opacity: 0.8;
  }
`;

export const BookingListItemLogo = styled.img<ComponentProps>`
  width: ${({ size }) => (size?.isMobile ? '5rem' : '10rem')};
  object-fit: contain;
`;

export const BookingListItemInfo = styled.div<ComponentProps>`
  > div {
    margin: 3% 0;
  }
  display: flex;
  flex-direction: column;
  ${({ size }) => size?.isMobile && 'padding: 2%;'}
  ${({ size }) => size?.isMobile && 'text-align: center;'}
`;

export const BookingListItemInfoDate = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};*
  color: ${COLORS.BLUE_SILKKE};
`;

export const BookingListItemInfoAddress = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: black;
`;
export const BookingListItemInfoPrice = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  text-transform: uppercase;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: ${COLORS.BLUE_SILKKE};
  font-weight: bold;
`;
