export default {
  'global.confirm': 'Confirm',
  'global.next': 'Next',
  'global.previous': 'Previous',
  'global.back': 'Back',
  'global.cancel': 'Cancel',
  'global.backHome': 'Back to Home',
  'global.close': 'Close',
  'global.seeMore': 'Load more',
};
