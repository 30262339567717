import COLORS from 'color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PopupContainer = styled.div``;

export const PopupTitle = styled.div`
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.BLUE_SILKKE};
  text-transform: uppercase;
`;

export const PopupContent = styled.div`
  font-size: 1rem;
  font-family: "Urbanist", sans-serif;
  > p {
    margin: 0.5rem 0 !important;
    text-align: center;
  }
`;

export const PopupButton = styled(Link)<{ size?: any }>`
  background: ${COLORS.BLUE_SILKKE};
  font-size: 1.2rem;
  color: white !important;
  display: block;
  margin: ${({ size }) => (size?.isMobile ? '10%' : '5%')} auto;
  width: auto;
  border: none;
  width: auto;
  list-style: none;
  text-decoration: none;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  &:hover {
    background: ${COLORS.BLUE_SILKKE_DARK};
  }
  transition: background 0.2s ease;
`;
