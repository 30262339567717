import styled from 'styled-components';
import COLORS from 'color';
import { ScreenSize } from 'type/ScreenSize';

export const SliderButton = styled.div<{ size: ScreenSize }>`
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  font-size: ${({ size }) => (size.isDesktop ? '5vmin' : '10vmin')};
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 900;
`;

export const Palette = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ColorWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
`;

export const ColorPin = styled.div<{
  color: string;
  selected: boolean;
  size: ScreenSize;
}>`
  cursor: pointer;
  user-select: none;
  background: ${({ color }) => color};
  width: ${({ size }) => (size.isDesktop ? '1vmin' : '6vmin')};
  height: ${({ size }) => (size.isDesktop ? '1vmin' : '6vmin')};
  border-radius: 50%;
  margin: 0 1rem;
  border: 2px solid transparent;
  ${({ selected }) => selected && `border: 3px solid ${COLORS.BLUE_SILKKE};`}
`;

export const ImageColor = styled.div<{
  url: string;
  size: ScreenSize;
  selected: boolean;
}>`
  width: 5rem;
  height: 5rem;
  ${({ selected }) => selected && `border: 3px solid ${COLORS.BLUE_SILKKE};`}
  border-radius: 50%;
  overflow: hidden; /* Cache les parties de l'image qui débordent */
  background-image: url(${({ url }) => url});
  background-position: 50% 10%;
  cursor: pointer;
  user-select: none;
  background-size: 300%;
`;
