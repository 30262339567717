/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';

import LoadingSpinner from 'component/LoadingSpinner';
import useResponsive from 'hooks/useResponsive';
import { useParams } from 'react-router-dom';
import { deleteUserMovie, getUserMovie } from 'providers/movie';
import Notification from 'component/Notification';
import ConfirmModal from 'component/modal/ConfirmModal';

import BackButton from 'component/Back';
import { useTranslation } from 'react-i18next';
import * as style from './MovieDetailsStyle';

export const Movies: React.FC = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const randomKeyString = Math.random().toString(36).substring(7);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const { idMovie, idPicture3d } = useParams();
  const [errorMessage, setErrorMessage] = React.useState<string | null>('');
  const [movie, setMovie] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  useEffect(() => {
    document.title = t('title.movieDetails');
    if (idMovie && idPicture3d) {
      setLoading(true);
      getUserMovie(idMovie, idPicture3d)
        .then((res) => {
          setMovie(res.data.movie);
          setLoading(false);
        })
        .catch((err) => setErrorMessage(t(err.response.data.message)));
    }
  }, [idMovie, idPicture3d]);

  const deleteVideo = () => {
    setShowConfirm(false);
    setLoading(true);
    if (idMovie && idPicture3d) {
      deleteUserMovie(idMovie, idPicture3d)
        .then((res) => {
          window.location.href = '/movie?success=deleted';
        })
        .catch((err) => setErrorMessage(t(err.response.data.message)));
    }
  };

  return loading ? (
    <LoadingSpinner shouldBeCenter />
  ) : !showConfirm ? (
    <style.Container>
      <BackButton to="/movie" />

      <style.VideoMovie
        src={movie.url}
        controls
        size={screenSize}
        autoPlay
        loop
      />
      <style.MovieCommands size={screenSize}>
        {/*    <button type="button" onClick={() => setShowConfirm(true)}>
          {t('movie.movieDetails.delete')}
        </button> */}
        {/* <button type="button"><FormattedMessage id="movie.movieDetails.share" /></button> */}
        {/*       <button type="button">
          <a href={movie.url} download>
            {t('movie.movieDetails.download')}
          </a>
        </button> */}
      </style.MovieCommands>
      <style.MovieDetails>
        <style.MovieTitle>
          {movie && movie.movie && `${movie.movie.libel} ${movie.idPicture3D}`}
        </style.MovieTitle>
        <style.MovieSubTitle>
          {movie?.picture3_d?.capsule_location?.company
            && Object.keys(movie.picture3_d.capsule_location.company).length
            !== 0 ? (
              <>
                {t('booking.price.free')}
                {movie?.picture3_d?.capsule_location?.company?.logo ? (
                  <img
                    src={movie?.picture3_d?.capsule_location?.company.logo}
                    alt="logo"
                  />
                ) : (
                  <span>
                    {movie?.picture3_d?.capsule_location?.company?.name}
                  </span>
                )}
              </>
            ) : null}
        </style.MovieSubTitle>
      </style.MovieDetails>
      {errorMessage && <Notification type="error" message={errorMessage} key={randomKeyString} />}
    </style.Container>
  ) : (
    <ConfirmModal
      title={t('movie.movieDetails.confirmDelete')}
      body={t('movie.movieDetails.confirmDelete.message')}
      onConfirm={deleteVideo}
      onCancel={() => setShowConfirm(false)}
    />
  );
};

export default Movies;
