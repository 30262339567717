/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import useResponsive from 'hooks/useResponsive';

import Button from 'component/Button';
import { PreRegisterFormData } from 'type/PreRegisterFormData';
import { PreRegistering } from 'providers/auth';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import { useTranslation } from 'react-i18next';
import TurnstileWidget from 'component/Turnstile';
import * as style from './PreRegisterStyle';
import silkkeLogo from '../../../assets/img/Silkke_double_k_blue.png';

const PreRegister: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<string | null>('');
  const [errorMessage, setErrorMessage] = React.useState<string | null>('');
  const [turnStileValidation, setTurnStileValidation] = React.useState<boolean>(false);
  const screenSize = useResponsive();
  const email = t('register.mail');
  const firstName = t('register.firstname');
  const lastName = t('register.lastname');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = t('title.preregister');
  }, []);
  const randomKeyString = Math.random().toString(36).substring(7);
  const sendRegistration = async (formData: PreRegisterFormData) => {
    setLoading(true);
    await PreRegistering(formData)
      .then(() => {
        setSuccessMessage(t('register.success'));
      })
      .catch((err) => {
        if (
          err.response.data.message === 'The email has already been taken.'
        ) {
          setErrorMessage(t('register.email.alreadyUsed'));
        } else {
          setErrorMessage(err.response.data.message || err.message);
        }
      });
    setLoading(false);
  };
  return (
    <style.Background size={screenSize}>
      <style.Form size={screenSize} onSubmit={handleSubmit(sendRegistration)}>
        <style.FormHeader size={screenSize}>
          <style.SilkkeLogo src={silkkeLogo} />
          <style.FormHeaderTitle>{t('register.title')}</style.FormHeaderTitle>
        </style.FormHeader>

        <style.FormGroupInput>
          <style.FormInput
            {...register('firstName', { required: true })}
            placeholder={firstName}
            style={{ textTransform: 'capitalize' }}
          />
          {errors.firstName && (
            <style.FormError size={screenSize}>
              {t('register.firstName.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>
        <style.FormGroupInput>
          <style.FormInput
            {...register('lastName', { required: true })}
            placeholder={lastName}
            style={{ textTransform: 'uppercase' }}
          />
          {errors.lastName && (
            <style.FormError size={screenSize}>
              {t('register.lastName.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>
        <style.FormGroupInput>
          <style.FormInput
            {...register('email', {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
            placeholder={email}
          />

          {errors.email && (
            <style.FormError size={screenSize}>
              {t('register.email.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>

        <style.FormGroupInput>
          <style.FormCheckBoxGroup>
            <Checkbox {...register('terms', { required: true })} />
            <label
              htmlFor="terms"
              dangerouslySetInnerHTML={{
                __html: t('register.terms') || '',
              }}
            />
          </style.FormCheckBoxGroup>
        </style.FormGroupInput>
        <style.FormBottom>

          {loading ? (
            <LoadingSpinner style={{ marginTop: '1rem' }} />
          ) : (
            <Button
              width="auto"
              height="2.5rem"
              style={{ marginTop: '4%', padding: '0 2rem' }}
              disabled={!turnStileValidation}
            >
              {t('register.submit')}
            </Button>
          )}
          <style.SubLink to="/login" fontSize="1rem">
            {t('register.already')}
          </style.SubLink>

        </style.FormBottom>
        {/* {process.env.NODE_ENV !== 'development' && <TurnstileWidget isValidSetter={setTurnStileValidation} />} */}
        <TurnstileWidget isValidSetter={setTurnStileValidation} />

      </style.Form>
      {errorMessage ? <Notification type="error" message={errorMessage} key={randomKeyString} /> : null}
      {!errorMessage && successMessage ? <Notification type="success" message={successMessage} key={randomKeyString} /> : null}
    </style.Background>
  );
};

export default PreRegister;
