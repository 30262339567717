export default {
  'register.pre.header': 'Sign up',
  'register.title': 'Create an account',
  'register.mail': 'Email address',
  'register.firstname': 'First name',
  'register.lastname': 'Last name',
  'register.submit': 'Sign up',
  'register.already': 'Already registered?',
  'register.terms':
    'I accept the <a href="https://www.silkke.com/terms" target="_blank">terms of use and conditions</a>',
  'register.firstName.error': 'The first name is required',
  'register.lastName.error': 'The last name is required',
  'register.terms.error': 'You must accept the terms of use and conditions',
  'register.email.error': 'The email address is valid',
  'register.email.alreadyUsed': 'This email address is already used',
  'register.success': 'We just sent you an email to confirm your account',
  'complete.register.keyMissing': 'The key is missing',
  'complete.register.keyEmpty': 'The key is empty',
  'complete.register.wrongKey':
    "User wasn't found with the key provided. Check your mail and try again.",
  'complete.register.password': 'Password',
  'complete.register.title': 'Account',
  'complete.register.confirmPassword': 'Confirm password',
  'complete.register.phone': 'Phone',
  'complete.register.postalCode': 'Postal code',
  'complete.register.country': 'Country',
  'complete.register.birthdate': 'Birthdate',
  'complete.register.address': 'Address Line 1',
  'complete.register.complement': 'Address Line 2',
  'complete.register.city': 'City',
  'complete.register.save': 'save',
  'complete.register.chooseCountryError': 'You must choose a country',
  'complete.register.passwordNotMatch': 'The passwords do not match',
  'login.title': 'Sign in !',
  'login.mail': 'Email address',
  'login.password': 'Password',
  'login.submit': 'Sign in',
  'login.email.error': 'The email address is required',
  'login.password.forgot': 'Forgot your password?',
  'login.join': 'Join us',
  'login.password.error': 'The password is required',
  'login.error.credentials': 'Invalid credentials',
  'login.invalidCapsuleLocation': 'Invalid capsule location',
  'login.capsuleNotAvailable': 'Capsule not available',
  isRequired: 'is required',
  'login.password.lost.mail.success': 'We just sent you an email',
  'login.password.reset.success': 'Your password has been reset, you can login',
  'login.register.success': 'Your account has been created, you can login',
  'reset.password.title': 'Reset your password',
  'reset.password.button': 'Reset',
};
