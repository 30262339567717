/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import Header from 'component/global/Header/Header';
import LoadingSpinner from 'component/LoadingSpinner';
import { getAvatarPreview, getUserCollection } from 'providers/collection';
import Notification from 'component/Notification';
import { UserCollection } from 'type/UserCollection';
import useResponsive from 'hooks/useResponsive';
import Footer from 'component/global/Footer/Footer';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as collection from './CollectionStyle';

type MovieProps = {
  avatar: any;
  toggle: any;
  collectionMovieRef: React.RefObject<any>;
  changeState: (event: any, state: 'info' | 'avatar' | 'movie') => void;
  oldImage?: boolean;
};

const Movie = ({
  toggle,
  collectionMovieRef,
  changeState,
  avatar,
  oldImage,
}: MovieProps) => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const [preview, setPreview] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isGif, setIsGif] = React.useState(false);
  const [previewLoaded, setPreviewLoaded] = React.useState(false);
  useEffect(() => {
    getAvatarPreview(avatar.idPicture3D).then((res) => {
      setPreview(res.data.preview);
      setLoading(false);
    });
  }, [avatar.idPicture3D]);

  useEffect(() => {
    if (preview) {
      const urlWithoutParams = preview.split('?')[0];
      setIsGif(urlWithoutParams.endsWith('.gif'));
    }
  }, [preview]);

  return loading ? (
    <div style={{ minHeight: '70vh' }}>
      <LoadingSpinner />
    </div>
  ) : isGif && preview ? (
    <div style={{ minHeight: '70vh' }}>
      {' '}
      <collection.DetailsButton
        onClick={toggle}
        size={screenSize}
        oldImage={oldImage}
      >
        i
      </collection.DetailsButton>
      <collection.PlayButton
        onClick={(e) => changeState(e, 'movie')}
        oldImage={oldImage}
      >
        <span />
      </collection.PlayButton>
      <collection.CollectionItemGif
        style={{ visibility: previewLoaded ? 'visible' : 'hidden' }}
        size={screenSize}
        src={preview}
        ref={collectionMovieRef}
        onLoad={() => {
          setPreviewLoaded(true);
        }}
      />
    </div>
  ) : (
    <div style={{ minHeight: '70vh' }}>
      <collection.DetailsButton onClick={toggle} size={screenSize}>
        i
      </collection.DetailsButton>
      <collection.PlayButton onClick={(e) => changeState(e, 'movie')}>
        <span />
      </collection.PlayButton>
      <collection.CollectionItemMovie
        style={{ visibility: previewLoaded ? 'visible' : 'hidden' }}
        size={screenSize}
        autoPlay
        loop
        playsInline
        src={preview}
        ref={collectionMovieRef}
        onLoadedData={() => {
          setPreviewLoaded(true);
        }}
      />
    </div>
  );
};
type InfoProps = {
  toggle: any;
  avatar: any;
  changeState: (event: any, state: 'info' | 'avatar' | 'movie') => void;
  collectionItemInfoRef: React.RefObject<HTMLDivElement>;
  oldImage?: boolean;
};
const Info = ({
  toggle,
  avatar,
  changeState,
  collectionItemInfoRef,
  oldImage,
}: InfoProps) => {
  const screenSize = useResponsive();
  const { t } = useTranslation();
  return (
    <>
      <collection.DetailsButton
        onClick={toggle}
        size={screenSize}
        oldImage={oldImage}
      >
        i
      </collection.DetailsButton>
      <collection.PlayButton
        onClick={(e) => changeState(e, 'movie')}
        oldImage={oldImage}
        size={screenSize}
      >
        <span />
      </collection.PlayButton>
      <collection.CollectionItemInfo
        size={screenSize}
        ref={collectionItemInfoRef}
      >
        <collection.InnerCollectionItemInfo>
          <div>
            <h1 className="title" style={{ fontWeight: 'bold' }}>
              {avatar.capsule_location.libel}
            </h1>

            <h1 className="title" style={{ margin: '2% 0;' }}>
              {new Date(avatar.dateAvatar)
                .toLocaleDateString('fr-FR')
                .replace(/\//g, '.')}
            </h1>
            <h1>{avatar.idPicture3D}</h1>
          </div>

          {avatar.capsule_location.company && (
            <div className="bottom">
              <div>
                {t('booking.price.free')}
                {' '}

              </div>
              <div>
                {avatar.capsule_location.company.logo ? (
                  <img
                    src={avatar.capsule_location.company.logo}
                    alt="logo company"
                  />
                ) : (
                  <span>{avatar.capsule_location.company.name}</span>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginTop: '10%',
            }}
          >
            {/*          <collection.TooltipDiv>
              <input
                type="checkbox"
                id="authorizeFiguration"
                name="authorizeFiguration"
                checked={avatar.figuration === 1}
              />

              <label htmlFor="authorizeFiguration">
                Autoriser l&apos;avatar pour la figuration
              </label>
              <i className="fas fa-info-circle" />
              <span className="tooltiptext">
                {t('collection.tooltip.figuration')}
              </span>
            </collection.TooltipDiv> */}
          </div>
        </collection.InnerCollectionItemInfo>
      </collection.CollectionItemInfo>
    </>
  );
};

type AvatarProps = {
  toggle: any;
  avatar: any;
  changeState: (event: any, state: 'info' | 'avatar' | 'movie') => void;
  collectionItemRef: React.RefObject<HTMLImageElement>;
  setIsImageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  oldImage?: boolean;
};

const Avatar = ({
  toggle,
  avatar,
  changeState,
  collectionItemRef,
  setIsImageLoaded,
  oldImage,
}: AvatarProps) => {
  const screenSize = useResponsive();
  return (
    <>
      <collection.DetailsButton
        onClick={toggle}
        size={screenSize}
        oldImage={oldImage}
      >
        i
      </collection.DetailsButton>
      <collection.PlayButton
        onClick={(e) => changeState(e, 'movie')}
        oldImage={oldImage}
        size={screenSize}
      >
        <span />
      </collection.PlayButton>
      <collection.CollectionItem
        oldImage={oldImage}
        onLoad={() => setIsImageLoaded(true)}
        ref={collectionItemRef}
        size={screenSize}
        src={avatar.preview}
      />
    </>
  );
};
const CollectionCarouselItem = ({
  avatar,
  number,
  total,
}: {
  avatar: UserCollection;
  number: number;
  total: number;
}) => {
  const collectionItemRef = useRef<HTMLImageElement>(null);
  const collectionItemInfoRef = useRef<HTMLImageElement>(null);
  const collectionMovieRef = useRef<any>(null);
  const screenSize = useResponsive();
  const [display, setDisplay] = React.useState<string>('avatar');
  const [avatarWidth, setAvatarWidth] = React.useState<number>(0);
  const [avatarHeight, setAvatarHeight] = React.useState<number>(0);
  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  const changeState = (event: any, state: 'info' | 'avatar' | 'movie') => {
    event.stopPropagation();
    event.preventDefault();
    setDisplay(state);
  };
  const isOldImage = avatar.preview.split('?')[0].endsWith('00.png');
  useEffect(() => {
    if (isImageLoaded) {
      if (collectionItemRef?.current) {
        const width = collectionItemRef.current.offsetWidth;
        const height = collectionItemRef.current.offsetHeight;
        setAvatarWidth(width);
        setAvatarHeight(height);
      } else if (
        collectionItemInfoRef?.current
        && avatarWidth > 0
        && avatarHeight > 0
      ) {
        collectionItemInfoRef.current.style.width = `${avatarWidth}px`;
        collectionItemInfoRef.current.style.height = `${avatarHeight}px`;
      } else if (collectionMovieRef?.current) {
        collectionMovieRef.current.style.width = `${avatarWidth}px`;
        collectionMovieRef.current.style.height = `${avatarHeight}px`;
      }
    }
  }, [
    collectionItemRef?.current,
    collectionItemInfoRef?.current,
    isImageLoaded,
    display,
    avatarWidth,
    avatarHeight,
    screenSize,
  ]);
  const toggle = (e: any) => changeState(e, display === 'avatar' ? 'info' : 'avatar');
  return (
    <div
      style={{
        margin: '4% auto',
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <collection.BodyCollection size={screenSize} key={avatar.idPicture3D}>
        {display === 'info' ? (
          <Info
            toggle={toggle}
            changeState={changeState}
            avatar={avatar}
            collectionItemInfoRef={collectionItemInfoRef}
            oldImage={isOldImage || false}
          />
        ) : display === 'avatar' ? (
          <Avatar
            avatar={avatar}
            toggle={toggle}
            changeState={changeState}
            collectionItemRef={collectionItemRef}
            setIsImageLoaded={setIsImageLoaded}
            oldImage={isOldImage || false}
          />
        ) : (
          <Movie
            avatar={avatar}
            toggle={toggle}
            collectionMovieRef={collectionMovieRef}
            changeState={changeState}
          />
        )}
      </collection.BodyCollection>
    </div>
  );
};

const Collection: React.FC = () => {
  const { isLoading, data, error } = useQuery(
    'userAvatar',
    async () => getUserCollection(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
  const randomKeyString = Math.random().toString(36).substring(7);

  const [userCollection, setUserCollection] = React.useState<any>([]);
  const screenSize = useResponsive();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('title.collection');
    if (data) {
      console.log(data);
      const dataFormatted = Object.values(data.data.collection).sort(
        (a: any, b: any) => b.idPicture3D - a.idPicture3D,
      );
      setUserCollection(dataFormatted);
    }
  }, [data]);
  return (
    <collection.Container>
      <Header title="Digital" subTitle="Human" />
      <collection.Main>
        {isLoading || error ? (
          <>
            <LoadingSpinner shouldBeCenter />
            {error && (
              <Notification
                type="error"
                message={`An error occurred ${error.toString()}`}
                key={randomKeyString}
              />
            )}
          </>
        ) : (
          <Grid
            container
            spacing={screenSize.isMobile ? 0 : 2}
            style={{
              width: '90%',
              margin: screenSize.isMobile ? '2% auto' : '0 auto 0 auto',
            }}
          >
            {userCollection
              .filter(
                (collectionItem: any) => collectionItem.preview !== undefined,
              )
              ?.map((avatar: UserCollection, index: any) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={4}
                  key={avatar.idPicture3D}
                >
                  <CollectionCarouselItem
                    avatar={avatar}
                    number={index + 1}
                    total={userCollection.length}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </collection.Main>
      <Footer title="" />
    </collection.Container>
  );
};

export default Collection;
