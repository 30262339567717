/*   */
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import COLORS from 'color';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  background-color: ${COLORS.GAINSBORO};
  display: flex;
  max-height: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 100%;
`;
Container.displayName = 'Container';
export const Main = styled.div`
  /* min height must fill empty space */
  min-height: 74.5%;
  max-height: 74.5%;
  min-width: 100%;
  max-width: 100%;
  background-color: ${COLORS.GAINSBORO};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;
Main.displayName = 'Main';
export const slideLeft = keyframes`

from {
  transform: translateX(0);
}
to {
  transform: translateX(300%);
}
`;

export const slideRight = keyframes`


  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-300%);
  }
`;
export const DetailsButton = styled.button<
ComponentProps & { oldImage?: boolean }
>`
  background-color: ${COLORS.BLUE_SILKKE};
  border: none;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 130%;
  border: 2px solid white;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  padding: 0.5rem 1.2rem;
  right: ${({ size, oldImage }) => (size?.isDesktop && oldImage ? '-12%' : '-12%')};
  transform: translateX(-50%);
  bottom: ${({ size, oldImage }) => (size?.isMobile && oldImage ? '82%' : '85%')};
  z-index: 2;
  width: 3rem; // set a fixed width for both buttons
`;
DetailsButton.displayName = 'DetailsButton';

export const PlayButton = styled.button<
ComponentProps & { oldImage?: boolean; movie?: boolean }
>`
  background-color: ${COLORS.BLUE_SILKKE};
  display: block;
  border-radius: 5px;
  cursor: pointer;
  font-family: Urbanist, sans-serif;
  font-size: 130%;
  border: 2px solid white;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  position: absolute;
  padding: 0.7rem 0.9rem;
  right: ${({ size, oldImage }) => (size?.isDesktop && oldImage ? '-12%' : '-12%')};
  transform: translateX(-50%);
  bottom: ${({ size, oldImage }) => (size?.isMobile && oldImage ? '70%' : '75%')};
  z-index: 2;
  width: 3rem; // set a fixed width for both buttons

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid white;
  }
};`;
PlayButton.displayName = 'PlayButton';
/* remember we can't use boolean and camelcase property on Link component */
export const BodyCollection = styled.div<ComponentProps>`
  margin: 0 auto;
  /* center */
  display: inline-block;
  width: auto;
  cursor: pointer;
  position: relative;
`;
BodyCollection.displayName = 'BodyCollection';

export const CollectionItem = styled.img<
ComponentProps & {
  direction?: string;
  noAnimation?: boolean;
  oldImage?: boolean;
}
>`
  border-radius: 5px;
  position: relative;
  height: auto;
  display: block;
  z-index: 1;
  transform-origin: center center;
  ${({ oldImage, size }) => oldImage && size?.isDesktop && 'min-height: 70vh;'}
  max-height: 70vh;
  object-fit: contain;
  max-width: ${({ oldImage, size }) => (oldImage && size?.isDesktop ? '20svw' : '84vw')};
  ${({ oldImage }) => oldImage && `border: 1px solid ${COLORS.GREY};`}
  &:hover {
    ${({ size }) => size?.isDesktop && `border: 2px solid ${COLORS.BLUE_SILKKE};`}
  }
`;

CollectionItem.displayName = 'CollectionItem';

export const CollectionItemInfo = styled.div<ComponentProps>`
  display: block;

  position: relative;
  border-radius: 5px;
  text-align: center;
  max-height: 70vh;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #454ece 0%, #bd7db7 100%);
`;
CollectionItemInfo.displayName = 'CollectionItemInfo';

export const CollectionItemMovie = styled.video<ComponentProps>`
  display: block;

  position: relative;
  border-radius: 5px;
  text-align: center;
  max-height: 70vh;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #454ece 0%, #bd7db7 100%);
`;

export const InnerCollectionItemInfo = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  color: white;
  padding: 10% 0;
  > div {
    & > h1.title {
      font-size: 1.5rem;
      text-transform: uppercase;
    }
    & > * {
      margin: 2%;
    }
    & > p {
      font-size: 2.5rem;
    }
  }
  > div.bottom {
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      > span {
        font-weight: bold;
        margin-left: 1rem;
      }
      > img {
      }
    }
  }
`;

InnerCollectionItemInfo.displayName = 'InnerCollectionItemInfo';
export const ItemMarkers = styled.span<ComponentProps & { bottom?: string }>`
  position: absolute;
  bottom: ${({ bottom }) => bottom || '3%'}; // default value is 4%
  right: 3%;
  font-family: "Urbanist", sans-serif;
  z-index: 2;
`;

export const CollectionItemGif = styled.img<ComponentProps>`
  max-height: 70vh;
  max-width: 84svw;
`;

export const PreviewContainer = styled.div<ComponentProps>`
  min-height: 70vh;
`;

export const TooltipDiv = styled.div`
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    min-width: 10svw;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;
