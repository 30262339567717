import React, { useEffect } from 'react';
import * as modal from 'component/modal/ConfirmModalStyle';
import useResponsive from 'hooks/useResponsive';
import { useForm } from 'react-hook-form';
import { login, lostPassword } from 'providers/auth';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import Button from 'component/Button';
import { useTranslation } from 'react-i18next';
import TurnstileWidget from 'component/Turnstile';
import * as style from './LoginStyle';
import silkkeLogo from '../../../assets/img/Silkke_double_k_blue.png';
import listErrors from './errorMessageList';
import listSuccess from './sucessMessageList';

const LostPasswordForm: React.FC<{ onClose: any }> = ({ onClose }) => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const [info, setInfo] = React.useState<string | null>('');
  const [error, setError] = React.useState<string | null>('');
  const randomKeyString = Math.random().toString(36).substring(7);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const sendLostPassword = (data: any) => {
    setLoading(true);
    lostPassword(data)
      .then(() => {
        setLoading(false);
        setInfo(t('login.password.lost.mail.success'));
        setTimeout(() => {
          const redirectionLink = new URLSearchParams(window.location.search).get('f_r');
          if (redirectionLink) {
            window.history.go(-1);
          }
        }, 3000);
      })
      .catch((err) => {
        /* check status code */
        if (err.response.status === 404) {
          setError('User not found');
        } else {
          setError(err.response.data.message);
        }
      });
  };
  return (
    <modal.ModalContainer>
      <modal.ModalContent size={screenSize}>
        <modal.ModalHeader>{t('login.password.forgot')}</modal.ModalHeader>
        <modal.ModalBody>
          <form
            style={{ width: '100%' }}
            onSubmit={handleSubmit(sendLostPassword)}
          >
            <style.FormGroupInput style={{ margin: '0' }}>
              <style.FormInput
                style={{ width: '70%' }}
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                placeholder="Email"
              />

              {errors.email && (
                <style.FormError size={screenSize} style={{ margin: '5% 0 0' }}>
                  {t('login.email.error')}
                </style.FormError>
              )}
            </style.FormGroupInput>
            <modal.ModalFooter style={{ marginTop: '5%' }}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <modal.ModalButton
                    size={screenSize}
                    type="button"
                    onClick={onClose}
                  >
                    {t('modal.cancel')}
                  </modal.ModalButton>
                  <modal.ModalButton size={screenSize} type="submit">
                    {t('modal.confirm')}
                  </modal.ModalButton>
                </>
              )}
            </modal.ModalFooter>
          </form>
        </modal.ModalBody>
      </modal.ModalContent>
      {info && (
        <Notification type="success" message={info} key={randomKeyString} />
      )}
      {error && (
        <Notification type="error" message={error} key={randomKeyString} />
      )}
    </modal.ModalContainer>
  );
};
const Login: React.FC = () => {
  const { t } = useTranslation();
  const [lostModal, setLostModal] = React.useState<boolean>(
    window.location.hash.startsWith('#forgot'),
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [turnStileValidation, setTurnStileValidation] = React.useState<boolean>(false);
  const screenSize = useResponsive();
  const email = t('login.mail');
  const password = t('login.password');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: localStorage.getItem('referer') || new URLSearchParams(window.location.search).get('referer') || '',
      password: '',
    },
  });

  useEffect(() => {
    document.title = t('title.login');
    const urlError = new URLSearchParams(window.location.search).get('error');
    const urlMessage = new URLSearchParams(window.location.search).get(
      'message',
    );
    if (urlError && listErrors[urlError]) {
      setErrorMessage(listErrors[urlError]);
    } else if (urlMessage && listSuccess[urlMessage]) {
      setSuccessMessage(listSuccess[urlMessage]);
    }
  }, []);

  const handleRedirection = () => {
    const probableSavedUrl = localStorage.getItem('surl');
    if (typeof probableSavedUrl === 'string' && probableSavedUrl) {
      window.location.href = probableSavedUrl;
    } else {
      window.location.href = '/';
    }
  };

  const randomKeyString = Math.random().toString(36).substring(7);
  const submitLogin = (formData: any) => {
    setLoading(true);
    login({ ...formData })
      .then((response) => {
        const { token, lang } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('lang', lang);
        localStorage.removeItem('referer');
        handleRedirection();
      })
      .catch((error) => {
        const { data } = error.response;
        setErrorMessage(t(data.message)!);
        setLoading(false);
      });
  };
  const closeModal = () => {
    setLostModal(false);
  };
  const openModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLostModal(true);
  };

  return lostModal ? (
    <LostPasswordForm onClose={closeModal} />
  ) : (
    <style.Background size={screenSize}>
      <style.Form size={screenSize} onSubmit={handleSubmit(submitLogin)}>
        <style.FormHeader size={screenSize}>
          <style.SilkkeLogo src={silkkeLogo} />
          <style.FormHeaderTitle>{t('login.title')}</style.FormHeaderTitle>
        </style.FormHeader>

        <style.FormGroupInput>
          <style.FormInput
            {...register(
              'email',
              {
                required: true,
                pattern: /^\S+@\S+$/i,
              },
            )}
            placeholder={email}
          />

          {errors.email && (
            <style.FormError size={screenSize}>
              {t('login.email.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>
        <style.FormGroupInput>
          <style.FormInput
            {...register('password', { required: true })}
            placeholder={password}
            type="password"
          />

          {errors.password && (
            <style.FormError size={screenSize}>
              {t('login.password.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>
        <style.SubLinkButton type="button" onClick={openModal}>
          {t('login.password.forgot')}
        </style.SubLinkButton>

        <style.FormBottom>

          {loading ? (
            <LoadingSpinner style={{ marginTop: '10%' }} />
          ) : (
            <Button
              width="auto"
              height="2.5rem"
              style={{ marginTop: '4%', padding: '0 2rem' }}
              disabled={!turnStileValidation}
            >
              {t('login.submit')}
            </Button>
          )}
          <style.SubLink to="/register" fontSize="1rem">
            {t('login.join')}
          </style.SubLink>

        </style.FormBottom>
        {/* {process.env.NODE_ENV !== 'development' && <TurnstileWidget isValidSetter={setTurnStileValidation} />} */}
        <TurnstileWidget isValidSetter={setTurnStileValidation} />
      </style.Form>

      {errorMessage ? (
        <Notification
          type="error"
          message={errorMessage}
          key={randomKeyString}
        />
      ) : null}
      {!errorMessage && successMessage ? (
        <Notification
          type="success"
          message={successMessage}
          key={randomKeyString}
        />
      ) : null}
    </style.Background>
  );
};

export default Login;
