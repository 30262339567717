import React, { useMemo } from 'react';
import useResponsive from 'hooks/useResponsive';
import ColorPaletteSlider from 'component/global/Palette/Palette';
import { usePreview } from 'hooks/usePreviews';
import { useQuery } from 'react-query';
import { getHairColorPreview } from 'providers/style';
import { Tooltip } from '@mui/material';
import * as S from './HairColorSelectionStyle';
import hairColor from './HairColor';

const HairColorSelection = ({
  hairColors,
  stateHairColor,
  stateMeshColor,
  stateEndColor,
}: any) => {
  const responsive = useResponsive();
  const colorCountDisplayInPalettes = 4;
  const [hair, setHairColor] = stateHairColor;
  const [mesh, setMeshColor] = stateMeshColor;
  const [end, setEndColor] = stateEndColor;
  const [selectedPartColoration, setSelectedPartColoration] = React.useState<'hair' | 'end' | 'mesh'>('hair');
  const states = {
    hair,
    mesh,
    end,
  };
  const idStyleAssetColorPreview = useMemo(() => states[selectedPartColoration as keyof typeof states].idStyleAsset
    || states[selectedPartColoration as keyof typeof states], [states]);
  const HairColorPreviewsUrl = usePreview(
    [
      `Style_Assets/Hairs/Color/${idStyleAssetColorPreview
        .toString()
        .replace(idStyleAssetColorPreview.toString().charAt(0), '6')}.jpg`,
    ] || [],
  );

  const colorDataForPalette = (hairColor_: any) => hairColor_
    .map((color: any) => ({
      libel: hairColor[color.libel],
      idStyleAsset: color.idStyleAsset,
    }));
  // Création des palettes de couleurs pour chaque type de cheveux
  const AllPalettes = {
    mesh: hairColors.mesh && (
      <ColorPaletteSlider
        type="color"
        displayCount={colorCountDisplayInPalettes}
        colors={colorDataForPalette(hairColors.mesh.colors)}
        setColor={setMeshColor}
        style={{ marginLeft: responsive.isDesktop ? '1%' : '0' }}
      />
    ),
    end: hairColors.end && (
      <ColorPaletteSlider
        type="color"
        displayCount={colorCountDisplayInPalettes}
        colors={colorDataForPalette(hairColors.end.colors)}
        setColor={setEndColor}
        style={{ marginLeft: responsive.isDesktop ? '1%' : '0' }}
      />
    ),
    hair: hairColors.hair && (
      <ColorPaletteSlider
        type="color"
        displayCount={colorCountDisplayInPalettes}
        colors={colorDataForPalette(hairColors.hair.colors)}
        setColor={setHairColor}
        style={{ marginLeft: responsive.isDesktop ? '1%' : '0' }}
      />
    ),
  };

  return (
    <S.HairBuilderActions size={responsive}>
      <S.HairColorPreview
        size={responsive}
        url={HairColorPreviewsUrl[0] || ''}
      />
      <>
        {' '}
        <S.ActionsWrapper>
          <S.ToggleButton type="button" onClick={() => setSelectedPartColoration(selectedPartColoration === 'hair' ? 'end' : selectedPartColoration === 'end' ? 'mesh' : 'hair')}>

            {selectedPartColoration}
          </S.ToggleButton>
          {/*           <S.CustomSelect
            responsive={responsive}
            onChange={(e) => setHairPartColorationEdition(e.target.value)}
          >
            {Object.keys(AllPalettes).map((part: string) => {
              const key = part as 'hair' | 'end' | 'mesh';
              const HC = hairColors[key];
              return (
                <S.CustomOption key={key} value={key}>
                  {HC.libel}
                </S.CustomOption>
              );
            })}
          </S.CustomSelect> */}
        </S.ActionsWrapper>
        <S.ActionsWrapper>
          {selectedPartColoration && (
            AllPalettes[selectedPartColoration as keyof typeof AllPalettes]

          )}
        </S.ActionsWrapper>
      </>

    </S.HairBuilderActions>
  );
};

export default HairColorSelection;
