import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import messages from './i18n/index';

const savedLanguage = localStorage.getItem('lang');
const browserLanguage = navigator.language.split('-')[0];
const validLanguages = ['fr', 'en'];

let languageToUse = savedLanguage || browserLanguage;

if (!validLanguages.includes(languageToUse)) {
  languageToUse = 'en'; // Langue par défaut si la langue du navigateur n'est pas valide
}

i18n.use(initReactI18next).init({
  resources: messages,
  debug: process.env.NODE_ENV === 'development',
  lng: languageToUse,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
