export default {
  'settings.title': 'Account',
  'settings.accountDetails.title': 'account details',
  'settings.accountDetails.edit': 'Edit',
  'settings.accountDetails.save': 'save',
  'settings.accountDetails.oldPassword': 'Old password',
  'settings.accountDetails.editPasswordTitle': 'password',
  'settings.accountDetails.badOldPassword': 'Incorrect old password',
  'settings.accountDetails.language.title': 'language',
};
