import React, { useState, useEffect } from 'react';

import useResponsive from 'hooks/useResponsive';
import ColorPaletteSlider from 'component/global/Palette/Palette';
import { getEyesForm, getStyleAssetsPreview } from 'providers/style';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import COLORS from 'color';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as S from './EyesBuilderStyle';
import EyesColor from './EyesColor';

type IrisType = 'ext' | 'int';
type EyesBuilderProps = {
  setEyesIds: React.Dispatch<React.SetStateAction<any>>;
};

const IRIS_EXT_TYPE = 11;
const IRIS_INT_TYPE = 10;
const SHADE_TYPE = 12;

type ShadeSelectorProps = {
  selectedShade: any;
  shadeList: any;
};

const ShadeSelector: React.FC<ShadeSelectorProps> = ({ selectedShade, shadeList }) => {
  const [shadeIndex, setShadeIndex] = useState(0);
  const { selectedShade: sShade, setSelectedShade } = selectedShade;
  useEffect(() => {
    setSelectedShade(shadeList[shadeIndex].idStyleAsset);
  }, [shadeIndex, setSelectedShade]);

  return (
    <S.ToggleButton type="button" onClick={() => setShadeIndex((shadeIndex + 1) % shadeList.length)}>
      {shadeList[shadeIndex].libel}
    </S.ToggleButton>
  );
};
/**
 * Renders the EyesBuilder component.
 *
 * @param {React.FC<EyesBuilderProps>} setEyesIds - A function to set the assets.
 * @return {React.ReactNode} The rendered EyesBuilder component.
 */
const EyesBuilder: React.FC<EyesBuilderProps> = ({ setEyesIds }) => {
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [preview, setPreview] = useState<string>('');
  const [eyesList, setEyesList] = useState<any>({});
  const [error, setError] = useState<string>('');

  const [selectedShade, setSelectedShade] = useState<any>('');
  const listIrisExt = !pageLoading && eyesList[IRIS_EXT_TYPE];
  const listIrisInt = !pageLoading && eyesList[IRIS_INT_TYPE];
  const shadeList = !pageLoading && eyesList[SHADE_TYPE];
  const [irisExtSelected, setSelectedIrisExtColor] = useState<any>(listIrisExt && listIrisExt.length > 0 ? listIrisExt[0].idStyleAsset : '');
  const [irisIntSelected, setSelectedIrisIntColor] = useState<any>(listIrisInt && listIrisInt.length > 0 ? listIrisInt[0].idStyleAsset : '');
  const [selectionIris, setSelectionIris] = useState<IrisType>('ext');
  useEffect(() => {
    if (listIrisExt && listIrisInt) {
      setSelectedIrisExtColor(listIrisExt[0].idStyleAsset);
      setSelectedIrisIntColor(listIrisInt[0].idStyleAsset);
    }
  }, [listIrisInt, listIrisExt]);
  const getImagePath = () => {
    const irisExtColorIdStyle = irisExtSelected;
    const irisIntColorIdStyle = irisIntSelected;
    return `Style_Assets/Eyes/Eyes Color/${irisIntColorIdStyle}_${irisExtColorIdStyle}_${selectedShade}.jpg`;
  };
  const randomKeyString = Math.random().toString(36).substring(7);

  const saveEyesIds = () => {
    const irisExtColorIdStyle = irisExtSelected;
    const irisIntColorIdStyle = irisIntSelected;
    setEyesIds([
      irisExtColorIdStyle,
      irisIntColorIdStyle,
      selectedShade.toString(),
    ]);
  };
  const eyesQuery = useQuery('eyes', async () => getEyesForm(), {
    onSuccess: (res) => {
      setPageLoading(false);
      setEyesList(res.data.eyes);
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const { t } = useTranslation();

  const previewQuery = useQuery(
    ['preview', irisExtSelected, irisIntSelected, selectedShade],
    async () => {
      const imageName = getImagePath();

      return getStyleAssetsPreview([imageName]);
    },
    {
      enabled: !!irisExtSelected && !!irisIntSelected && eyesQuery.isSuccess,
      onSuccess: (res) => {
        setPreview(res.data.preview);
        setPreviewLoading(false);
      },
      onError: (err) => {
        console.log(err);
      },
    },
  );
  const responsive = useResponsive();
  const irisPalettes = {
    ext: listIrisExt && (
      <ColorPaletteSlider
        displayCount={4}
        type="color"
        colors={listIrisExt.map((colorIris: any) => ({
          libel: EyesColor[colorIris.libel],
          idStyleAsset: colorIris.idStyleAsset,
        }))}
        setColor={setSelectedIrisExtColor}
      />
    ),
    int: listIrisInt && (
      <ColorPaletteSlider
        type="color"
        displayCount={4}
        colors={listIrisInt.map((colorIris: any) => ({
          libel: EyesColor[colorIris.libel],
          idStyleAsset: colorIris.idStyleAsset,
        }))}
        setColor={setSelectedIrisIntColor}
      />
    ),
  };
  useEffect(() => {
    if (shadeList.length > 0) {
      setSelectedShade(shadeList[0].idStyleAsset);
    }
  }, [shadeList]);
  return (
    <>
      {!pageLoading ? (
        <S.EyesBuilderContainer size={responsive}>
          <S.NextButton
            style={{ position: 'absolute', right: '1rem', top: '1rem' }}
            onClick={() => saveEyesIds()}
          >
            {t('digital-human.create.preview.next')}
          </S.NextButton>
          {previewLoading ? (
            <LoadingSpinner shouldBeCenter />
          ) : (
            <S.EyesBuilderPreview
              src={preview}
              size={responsive}
              alt="digital human preview"
            />
          )}

          <S.EyesBuilderActions size={responsive}>
            <S.EyesBuilderAction>
              <S.ToggleButton type="button" onClick={() => setSelectionIris(selectionIris === 'ext' ? 'int' : 'ext')}>
                iris
                {' '}
                {selectionIris}
              </S.ToggleButton>
            </S.EyesBuilderAction>
            <S.EyesBuilderAction>

              {irisPalettes[selectionIris]}
            </S.EyesBuilderAction>
            <S.EyesBuilderAction>
              {shadeList && <ShadeSelector selectedShade={{ selectedShade, setSelectedShade }} shadeList={shadeList} />}
            </S.EyesBuilderAction>
          </S.EyesBuilderActions>
        </S.EyesBuilderContainer>
      ) : (
        <LoadingSpinner shouldBeCenter />
      )}
      {error.length > 0 && (
        <Notification type="error" message={error} key={randomKeyString} />
      )}
    </>
  );
};
export default EyesBuilder;
