import settings from './settings';
import navbar from './navbar';
import auth from './auth';
import user from './user';
import movie from './movie';
import modal from './modal';
import booking from './booking';
import collection from './collection';
import title from './title';
import digitalHuman from './avatar';
import global from './global';

const fr = {
  translation: {
    ...navbar,
    ...auth,
    ...user,
    ...settings,
    ...movie,
    ...modal,
    ...booking,
    ...collection,
    ...title,
    ...digitalHuman,
    ...global,
  },
};

export default fr;
