import { Navigate } from 'react-router-dom';
import React from 'react';
import { isLogged } from '../utils/variables';

const ProtectedRoute = ({ children }: any) => {
  if (!isLogged) {
    const path = window.location.pathname;
    localStorage.setItem('surl', path);
    return <Navigate to="/login" replace />;
  }
  return children;
};
export default ProtectedRoute;
