import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  height: 100%;
  background: ${COLORS.GAINSBORO};
`;

export const Booking = styled.div`
  width: 100%;
`;

export const BookingTime = styled.div<ComponentProps>`
  text-align: center;
  margin-top: ${({ size }) => (size?.isMobile ? '10%' : '3%')};
  & > button:nth-child(2) {
    margin-left: 10px;
  }
`;

export const BookingTimeButton = styled.button<
ComponentProps & { selected?: boolean }
>`
  width: auto;
  padding: 0 2rem;
  background: ${({ selected }) => (!selected ? COLORS.GAINSBORO : COLORS.BLUE_SILKKE)};
  color: ${({ selected }) => (!selected ? COLORS.BLUE_SILKKE : 'white')};
  border: 1px solid ${COLORS.BLUE_SILKKE};
  height: 2.5rem;
  font-size: 1.15rem;

  cursor: pointer;
`;

export const NewBooking = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  margin-top: ${({ size }) => (size?.isMobile ? '10%' : '3%')};

  & > h3 {
  }
`;

export const NewBookingButton = styled(Link)<ComponentProps>`
  text-align: center;
  align-self: center;
  font-family: "Urbanist", sans-serif;
  font-size: 1.3rem;
  display: block;
  margin: 0 auto;
  color: ${COLORS.BLUE_SILKKE};
`;

export const BookingList = styled.div<ComponentProps>`
  width: ${({ size }) => (size?.isMobile ? '90%' : '50%')};
  max-height: 50vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  margin: ${({ size }) => (size?.isMobile ? '3%' : '0')} auto;
`;

export const BookingListItem = styled(Link)<ComponentProps>`
  display: flex;
  margin: 2% 0;
`;

export const BookingListItemQrCode = styled.img<ComponentProps>`
  width: ${({ size }) => (size?.isMobile ? '50%' : '20%')};
`;

export const BookingListItemInfo = styled.div<ComponentProps>`
  padding: 2%;
  padding-top: ${({ size }) => (size?.isMobile ? '10%' : '5%')};
  > div {
    margin: 3% 0;
  }
  display: flex;
  flex-direction: column;
`;

export const BookingListItemInfoDate = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: ${COLORS.GREY};
`;

export const BookingListItemInfoAddress = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: ${COLORS.GREY};
`;

export const BookingListItemInfoPrice = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  color: #abacac;
  font-weight: bold;
`;
