import COLORS from 'color';
import styled from 'styled-components';
import { ScreenSize } from 'type/ScreenSize';

export const CustomSelect = styled.select<{ responsive: ScreenSize }>`
  border: 2px solid ${COLORS.BLUE_SILKKE}; // Couleur de bordure
  border-radius: 20px; // Rayon de bordure pour les coins arrondis
  padding: 0.5rem 1rem; // Espacement interne
  font-family: "Urbanist", sans-serif; // Police de caractères
  font-size: ${({ responsive }) => (responsive.isMobile || responsive.isTablet
    ? '0.70rem'
    : '1rem')}; // Taille de la police
  color: ${COLORS.BLUE_SILKKE}; // Couleur du texte
  text-align: center;
  ${({ responsive }) => (responsive.isMobile || responsive.isTablet
    ? 'width: 100%;'
    : 'width: auto;')}
  background-color: #ffffff; // Couleur de fond
  cursor: pointer; // Curseur en forme de pointeur
  outline: none; // Suppression de l'outline par défaut
  appearance: none; // Suppression du style par défaut du select
  -webkit-appearance: none; // Suppression du style par défaut du select pour Safari
  -moz-appearance: none; // Suppression du style par défaut du select pour Firefox
  justify-content: center;
  &:hover {
    background-color: #f0f0f0; // Couleur de fond au survol
  }
`;

export const ActionsWrapper = styled.div``;

// Vous pouvez également styliser les options si nécessaire
export const CustomOption = styled.option`
  color: #7f7fda; // Couleur du texte
`;

export const HairBuilderActions = styled.div<{ size: ScreenSize }>`
  position: absolute;
  bottom: 0;
  padding: 1rem;
  left: 50%;
  transform: translateX(-50%);
  ${({ size }) => size.isMobile && 'width: 90%;'}
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.GAINSBORO};
`;

export const HairColorPreview = styled.div<{ size: ScreenSize; url: string }>`
  position: absolute;
  left: ${({ size }) => (size.isMobile ? '5vw' : '0vw')};
  top: ${({ size }) => (size.isMobile ? '-12vh' : '-10vh')};
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden; /* Cache les parties de l'image qui débordent */
  background-image: url(${({ url }) => url});
  background-position: 50% 10%;
  background-size: 300%;
`;

export const ToggleButton = styled.button`
  border: 1px solid ${COLORS.BLUE_SILKKE};
  border-radius: 20px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.BLUE_SILKKE};
`;
