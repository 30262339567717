import React, { useEffect } from 'react';

import useResponsive from 'hooks/useResponsive';
import { createFreeBooking } from 'providers/booking';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import { useTranslation } from 'react-i18next';
import * as style from './BookingFreeStyle';

const BookingFree: React.FC<{ capsule: any }> = ({ capsule }) => {
  const { t } = useTranslation();
  const date = new URLSearchParams(window.location.search).get('date');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const randomKeyString = Math.random().toString(36).substring(7);
  useEffect(() => {
    document.title = t('title.freeBooking');
  }, []);
  const booking = () => {
    setLoading(true);
    if (date) {
      createFreeBooking(capsule.idCapsuleLocation, date)
        .then((response) => {
          if (response.data.success) {
            const fastpass = new URLSearchParams(window.location.search).get(
              'fastpass',
            );
            const href = `/booking/${response.data.booking.idBooking}${
              fastpass === 'true' ? '?fastpass=true' : ''
            }`;
            window.location.href = href;
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setLoading(false);
        });
    }
  };
  const screenSize = useResponsive();
  return (
    <style.Container size={screenSize}>
      <style.Title>{t('booking.free.title')}</style.Title>
      <style.SubTitle>{t('booking.free.subtitle')}</style.SubTitle>

      {capsule.location?.company?.logo ? (
        <style.Company
          size={screenSize}
          src={capsule.location?.company?.logo}
        />
      ) : (
        <style.InfoCompany size={screenSize}>{capsule.libel}</style.InfoCompany>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <style.Button size={screenSize} onClick={booking}>
          {t('booking.next')}
        </style.Button>
      )}
      {errorMessage && <Notification type="error" key={randomKeyString} message={errorMessage} />}
    </style.Container>
  );
};

export default BookingFree;
