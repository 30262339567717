type HairColor = {
  [key: string]: string;
};

const hairColor: HairColor = {
  Pink: '#FF31EA',
  Black: 'black',
  redhead: '#BF8477',
  Blond: '#D0BE62',
  Brown: '#804A40',
  White: 'white',
  Grey: '#87858E',
  Red: '#C75442',
  Orange: '#E2A650',
  Green: '#00B307',
  Cyan: '#00C2FF',
  Blue: '#0047FF',
  Purple: '#6C00F6',
};

export default hairColor;
