import Header from 'component/global/Header/Header';
import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import { getUserBookingById } from 'providers/booking';
import LoadingSpinner from 'component/LoadingSpinner';

import { useParams } from 'react-router-dom';
import Button from 'component/Button';
import Footer from 'component/global/Footer/Footer';
import Notification from 'component/Notification';
import { useTranslation } from 'react-i18next';
import * as style from './BookingStyle';

const Booking: React.FC = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const randomKeyString = Math.random().toString(36).substring(7);
  const [error, setError] = React.useState<string>('');
  const { idBooking } = useParams<{ idBooking: string }>();
  const [bookingDetails, setBookingDetails] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    document.title = t('title.yourBooking');
    if (idBooking) {
      getUserBookingById(idBooking)
        .then((res) => {
          setBookingDetails(res.data.booking);
          if (new URLSearchParams(window.location.search).get('fastpass')) {
            localStorage.removeItem('token');
          }
          setLoading(false);
        })
        .catch((err) => setError(err.response.data.message));
    }
  }, []);

  const downloadImage = (qrcode: string) => () => {
    const link = document.createElement('a');
    link.href = `${qrcode}`;
    link.download = 'qrcode.jpg';
    link.click();
  };
  return (
    <style.Container>
      <Header
        title="Book"
        subTitle="Capsule"
        desktopLinks={
          !new URLSearchParams(window.location.search).get('fastpass')
        }
      />
      {loading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <>
          <style.Booking>
            <style.BookingTitle size={screenSize}>
              {bookingDetails.upcoming
                ? t('booking.confirmed')
                : t('booking.passed')}
            </style.BookingTitle>

            {bookingDetails.upcoming && (
              <>
                <style.BookingInfo size={screenSize}>
                  {t('booking.info')}
                </style.BookingInfo>

                <style.BookingQrCode
                  size={screenSize}
                  src={bookingDetails.qrcode}
                />

                <style.BookingInfo size={screenSize}>
                  {t('booking.safe')}
                </style.BookingInfo>
                <style.BookingDownload
                  size={screenSize}
                  onClick={downloadImage(bookingDetails.qrcode)}
                  href={bookingDetails.qrcode}
                >
                  {t('booking.download')}
                </style.BookingDownload>
              </>
            )}
          </style.Booking>
          <Footer
            title={t('booking.download.step')}
            step={4}
            totalStep={4}
          />
        </>
      )}
      {error && <Notification key={randomKeyString} message={error} type="error" />}
    </style.Container>
  );
};

export default Booking;
