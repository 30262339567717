import useResponsive from 'hooks/useResponsive';
import React from 'react';
import * as footer from './FooterStyle';

interface FooterProps {
  title: string;
  step?: number;
  totalStep?: number;
}

export const Footer = ({ title, step, totalStep }: FooterProps) => {
  const screenSize = useResponsive();
  const fullwidth = step === undefined || totalStep === undefined;
  const height = screenSize.isMobile ? '5.3%' : '5%';
  return (
    <footer.Container height={height}>
      <footer.LeftSide size={screenSize} fullWidth={fullwidth}>
        <p>{title}</p>
      </footer.LeftSide>
      {step !== undefined || totalStep !== undefined ? (
        <footer.RightSide size={screenSize}>
          <p>
            {' '}
            {step}
            /
            {totalStep}
          </p>
        </footer.RightSide>
      ) : null}
    </footer.Container>
  );
};

Footer.defaultProps = {
  step: undefined,
  totalStep: undefined,
};

export default Footer;
