import COLORS from 'color';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div<{ height?: string }>`
  height: ${({ height }) => height || 'auto'};
  width: 100%;
  font-family: Urbanist, sans-serif;
  background: ${COLORS.GAINSBORO};
  border-top: 0.5px solid rgba(0, 0, 0, 0.28);
  display: flex;
`;

export const LeftSide = styled.div<{ fullWidth?: boolean } & ComponentProps>`
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(75% - 0.5px)')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(75% - 0.5px)')};
  display: flex;
  font-size: ${({ size }) => (size?.isMobile || size?.isTablet ? '5vw' : '1.5vw')};
  align-items: center;
  justify-content: ${({ size }) => (size?.isDesktop ? 'center' : 'unset')};
  border-right: 0px solid rgba(0, 0, 0, 0.28);
  > p {
    font-weight: bold;
    padding-left: 5%;
    color: rgba(0, 0, 0, 0.28);
  }
`;

export const RightSide = styled.div<ComponentProps>`
  min-width: 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    font-weight: bold;
    font-size: 1.4rem;
    color: ${COLORS.BLUE_SILKKE};
  }
`;
