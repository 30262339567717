import React, { useEffect } from 'react';
import Header from 'component/global/Header/Header';
import { useQuery } from 'react-query';
import { getUserMovies } from 'providers/movie';
import LoadingSpinner from 'component/LoadingSpinner';
import useResponsive from 'hooks/useResponsive';
import Notification from 'component/Notification';
import { useTranslation } from 'react-i18next';
import Button from 'component/Button';
import { cp } from 'fs';
import * as style from './MovieListStyle';

interface MovieCardProps {
  preview: string;
  title: string;
  idMovie: number;
  idPicture3d: number;
  movieDate: string;
  eventName?: string;
}

const MovieCard: React.FC<MovieCardProps> = ({
  preview,
  title,
  idMovie,
  eventName,
  idPicture3d,
  movieDate,
}: any) => {
  const { i18n } = useTranslation();
  const screenSize = useResponsive();
  const formattedDate = new Date(movieDate).toLocaleDateString(i18n.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <style.MovieCardContainer size={screenSize}>
      <style.MovieCardVideo controls size={screenSize} src={preview} />
      <style.MovieCardDetails size={screenSize}>
        <style.MovieCardTitle size={screenSize}>{title}</style.MovieCardTitle>
        <style.MovieCardEvent size={screenSize}>
          {eventName}
        </style.MovieCardEvent>
        <style.MovieCardDate size={screenSize}>
          {formattedDate}
        </style.MovieCardDate>
      </style.MovieCardDetails>
    </style.MovieCardContainer>
  );
};
export const Movies: React.FC = () => {
  const { t } = useTranslation();
  const randomKeyString = Math.random().toString(36).substring(7);
  const [page, setPage] = React.useState(1);
  const [message, setMessage] = React.useState<string | null>('');
  const [lastPageForThisUser, setLastPageForThisUser] = React.useState(0);
  const [loading, isLoading] = React.useState(true);
  const [movies, setMovies] = React.useState<any>([]);
  const [moviesTotalCount, setMoviesTotalCount] = React.useState(0);
  const queryMovies = async (p: number) => getUserMovies(p);

  useEffect(() => {
    document.title = t('title.movie');
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    if (success) {
      setMessage(t('movie.movieList.deletedMovie'));
    }

    queryMovies(page).then((data) => {
      setMovies(data.data.movies);
      setMoviesTotalCount(data.data.movies.total);
      setLastPageForThisUser(data.data.total_pages);
      isLoading(false);
    });
  }, []);

  const displayMore = () => {
    queryMovies(page + 1).then((data) => {
      setMovies([...movies, ...data.data.movies]);
      setPage(page + 1);
    });
  };

  return (
    <style.Container>
      <Header
        title={t('navbar.movie').split(' ')[0]}
        subTitle={t('navbar.movie').split(' ')[1]}
      />
      {loading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <style.ContainerMovie>
          {movies
            && movies.map((movieData: any, index: number) => (
              <MovieCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                idMovie={movieData.idMovie}
                idPicture3d={movieData.idPicture3D}
                preview={movieData.url}
                title={movieData.libel}
                movieDate={movieData.DA || ''}
                eventName={movieData.event_name}
              />
            ))}

          {page < lastPageForThisUser && moviesTotalCount > 3 && (
            <Button
              width="auto"
              disabled={page === lastPageForThisUser || loading}
              onClick={() => displayMore()}
            >
              {t('global.seeMore')}
            </Button>
          )}
        </style.ContainerMovie>
      )}
      {message && (
        <Notification type="success" message={message} key={randomKeyString} />
      )}
    </style.Container>
  );
};

export default Movies;
