import styled from 'styled-components';
import COLORS from 'color';
import { ScreenSize } from 'type/ScreenSize';

export const EyesBuilderContainer = styled.div<{ size: ScreenSize }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  ${({ size }) => size.isDesktop && 'width: 50%;'}
  ${({ size }) => size.isDesktop && 'margin: 0 auto;'}
`;
export const EyesBuilderPreview = styled.img<{ size: ScreenSize }>`
  ${({ size }) => (size.isDesktop ? 'max-width: 70%;' : 'max-width: 100%;')}
  ${({ size }) => (size.isDesktop ? 'max-height: 70%;' : 'max-height: 100%;')}
  display:block;
  ${({ size }) => size.isDesktop && 'margin: 0 auto;'}
  ${({ size }) => size.isMobile && 'height: 100%;'}
`;
export const EyesBuilderActions = styled.div<{ size: ScreenSize }>`
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  ${({ size }) => (size.isDesktop ? '' : 'min-height: 5rem;')}
  ${({ size }) => (size.isDesktop ? '' : 'width: 100%;')}
  border-radius: 20px 20px 0 0;
  justify-content: center;
  display: flex;
  align-items: center;
  // border: 1px solid ${COLORS.BLUE_SILKKE};
  background: ${COLORS.GAINSBORO};
`;

export const EyesBuilderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToggleButton = styled.button`
  border: 1px solid ${COLORS.BLUE_SILKKE};
  border-radius: 20px;
  padding: 0.3rem 1rem;
  cursor: pointer;

  font-size: 1rem;
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.BLUE_SILKKE};
`;
export const EyesBuilderActionLabel = styled.div<{ size: ScreenSize }>`
  min-width: 3rem;

  border-radius: 10px;
  font-family: "Urbanist", sans-serif;
  padding: 2%;
  text-align: center;
  color: ${COLORS.BLUE_SILKKE};
`;

export const EyesBuilderShade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    /* Cible tous les div enfants directement */
    flex: 1; /* Donne une flexibilité égale à tous les enfants */
  }
`;

export const NextButton = styled.button`
  border: none;
  border-radius: 10px;
  display: block;
  padding: 0.6rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 1rem;
  color: ${COLORS.BLUE_SILKKE};
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e0e0e0; // Couleur de fond au survol
  }
`;
