import COLORS from 'color';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';
import DottedBackground from 'assets/img/dotted_background.png';
import { Link } from 'react-router-dom';

export const Container = styled.div<ComponentProps>`
  display: flex;
  min-width: 100%;
  max-width: 100%;
  min-height: 20%;
  max-height: 20%;
  background: ${COLORS.GAINSBORO};
  border-bottom: ${({ size }) => (size?.isMobile ? 'none' : '1px solid rgba(0, 0, 0, 0.1)')};
`;

export const LeftBlock = styled.div<ComponentProps>`
  display: flex;
  justify-content: column;
  min-width: ${({ size }) => (size?.isDesktop ? '10%' : '35%')};
  align-items: center;
  padding: 0 20px;
  position: relative;
  background: url(${DottedBackground});
  background-size: cover;
  background-repeat: repeat;
`;

export const InnerLeftBlockLink = styled(Link)<ComponentProps>``;

export const InnerLeftBlock = styled.div<ComponentProps>`
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  font-family: "Urbanist", sans-serif;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;
  //
  a,
  span {
    font-size: ${({ size }) => (size?.isMobile || size?.isTablet ? '7vw' : '2vw')};
    color: ${COLORS.BLUE_SILKKE};
    font-weight: bold;
  }
  h3,
  h2,
  h4 {
    font-size: ${({ size }) => (size?.isMobile || size?.isTablet ? '6vw' : '1.5vw')};
    color: #bb7cb7;
  }
`;

export const RightBlock = styled.div<ComponentProps>`
  display: flex;
  width: 100%;
`;

export const RightBlockList = styled.ul<ComponentProps>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 5%;
  > li {
    font-family: "Urbanist", sans-serif;
    margin: 0 2%;

    font-size: 1.25rem;
  }
`;

export const RightBlockListLink = styled(Link)<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  margin: 0 2%;
`;

export default Container;
