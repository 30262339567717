import { useQuery } from 'react-query';
import { getHairForm } from 'providers/style';

type HairData = {
  hairs: {
    back: any;
    front: any;
    full: any;
    rear: any;
    top: any;
  };
  hairsColors: {
    hair: any;
    end: any;
    mesh: any;
  };
  status: string;
};

export const useHairData = (): HairData => {
  const { data, status } = useQuery('hair', async () => getHairForm());

  const hairs = {
    back: data?.data.hairs.filter(
      (hair: any) => hair.idStyleAssetType === 1,
    )[0],
    front: data?.data.hairs.filter(
      (hair: any) => hair.idStyleAssetType === 2,
    )[0],
    full: data?.data.hairs.filter(
      (hair: any) => hair.idStyleAssetType === 3,
    )[0],
    rear: data?.data.hairs.filter(
      (hair: any) => hair.idStyleAssetType === 4,
    )[0],
    top: data?.data.hairs.filter((hair: any) => hair.idStyleAssetType === 5)[0],
  };

  const hairsColors = {
    hair: data?.data.hairColor.filter(
      (color: any) => color.idStyleAssetType === 6,
    )[0],
    end: data?.data.hairColor.filter(
      (color: any) => color.idStyleAssetType === 7,
    )[0],
    mesh: data?.data.hairColor.filter(
      (color: any) => color.idStyleAssetType === 8,
    )[0],
  };

  return { hairs, hairsColors, status };
};
