import React, { useState, useEffect } from 'react';
import LoadingSpinner from 'component/LoadingSpinner';
import Header from 'component/global/Header/Header';
import { useForm } from 'react-hook-form';

import { resetPassword } from 'providers/auth';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import * as style from './ResetPasswordStyle';

const ResetPassword: React.FC = () => {
  const screenSize = useResponsive();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<any>('');
  const { t } = useTranslation();
  useEffect(() => {
    /* check url params key must be there and not empty */
    const urlParams = new URLSearchParams(window.location.search);

    document.title = t('title.resetPassword');
    const key = urlParams.get('key');
    if (!key) {
      window.location.href = '/login?error=userKeyNotFound';
    }
  }, []);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const sendResetPassword = (inputs: any) => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
    resetPassword({ ...inputs, key })
      .then((response) => {
        window.location.href = '/login?message=resetPasswordSuccess';
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const { data } = error.response;
          if (data.error === 'userKeyNotFound') {
            setInfo(t('complete.register.wrongKey'));
          } else {
            setInfo(t(data.message));
          }
        }
      });
  };
  return loading ? (
    <LoadingSpinner shouldBeCenter />
  ) : (
    <style.Container>
      <Header title="Reset" subTitle="your password" />
      <style.Form size={screenSize} onSubmit={handleSubmit(sendResetPassword)}>
        <style.Title>{t('reset.password.title')}</style.Title>
        <style.FormGroupInput size={screenSize}>
          <style.FormInput
            size={screenSize}
            {...register('password')}
            type="password"
            placeholder={t('complete.register.password')}
          />
        </style.FormGroupInput>
        <style.FormGroupInput size={screenSize}>
          <style.FormInput
            size={screenSize}
            {...register('password_confirmation')}
            type="password"
            placeholder={t('complete.register.confirmPassword')}
          />
        </style.FormGroupInput>
        <style.FormButton type="submit">
          {t('reset.password.button')}
        </style.FormButton>
      </style.Form>
    </style.Container>
  );
};

export default ResetPassword;
