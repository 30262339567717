import Header from 'component/global/Header/Header';
import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import { getUserBookings } from 'providers/booking';
import LoadingSpinner from 'component/LoadingSpinner';

import FailedImage from 'assets/img/failed.png';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as style from './BookingListStyle';

const List: React.FC<any> = ({ bookList }: any) => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  return bookList.map((booking: any) => {
    const dateFormatted = booking.dateBooking
      ? new Date(booking.dateBooking).toLocaleDateString('fr-FR')
      : '';
    const address = booking.address && booking.zipCode && booking.city
      ? `${booking.address} ${booking.zipCode} ${booking.city}`
      : '';
    let price = '';
    if (booking.price === 0) {
      price = `${t('booking.price.free')} ${booking.capsule_location?.company?.name}`;
    } else if (booking.price) {
      price = `${booking.price} €`;
    }
    const src = booking.qrcode ? `${booking.qrcode}` : FailedImage;
    return (
      <style.BookingListItem
        to={`/booking/${booking.idBooking}`}
        key={booking.idBooking}
      >
        <style.BookingListItemQrCode size={screenSize} src={src} alt="qrcode" />
        <style.BookingListItemInfo size={screenSize}>

          <style.BookingListItemInfoDate size={screenSize}>
            {dateFormatted}
          </style.BookingListItemInfoDate>
          <style.BookingListItemInfoAddress size={screenSize}>
            {address}
          </style.BookingListItemInfoAddress>

          <style.BookingListItemInfoPrice size={screenSize}>
            {price}
          </style.BookingListItemInfoPrice>
        </style.BookingListItemInfo>
      </style.BookingListItem>
    );
  });
};

const BookingList: React.FC = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const [upcomingBookingList, setUpcomingBookingList] = React.useState([]);
  const [previousBookingList, setPreviousBookingList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [bookingTime, setBookingTime] = React.useState('upcoming'); // ['upcoming', 'previous'
  /* react-query */
  const { isLoading, data } = useQuery('getUserBookings', async () => getUserBookings());

  useEffect(() => {
    document.title = t('title.bookingList');
    if (!isLoading && data) {
      const sortByMostRecent = (a: any, b: any) => {
        // TODO: add filter button etc ?
        const dateA = new Date(a.dateBooking);
        const dateB = new Date(b.dateBooking);
        return dateA < dateB ? 1 : -1;
      };
      setUpcomingBookingList(data.data.upcomingBooking.sort(sortByMostRecent));
      setPreviousBookingList(data.data.previousBooking.sort(sortByMostRecent));
      setLoading(false);
    }
  }, [isLoading, data]);

  return (
    <style.Container>
      <Header title="Book" subTitle="capsule" />
      {isLoading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <style.Booking>
          <style.NewBooking size={screenSize}>
            <style.NewBookingButton size={screenSize} to="/booking/new">
              {t('booking.add')}
            </style.NewBookingButton>

          </style.NewBooking>
          <style.BookingTime size={screenSize}>
            {
              upcomingBookingList.length > 0 && (

                <style.BookingTimeButton
                  size={screenSize}
                  onClick={() => setBookingTime('upcoming')}
                  type="button"
                  selected={bookingTime === 'upcoming'}
                >
                  {t('booking.upcoming')}
                </style.BookingTimeButton>
              )
            }
            {
              previousBookingList.length > 0 && (

                <style.BookingTimeButton
                  size={screenSize}
                  onClick={() => setBookingTime('previous')}
                  type="button"
                  selected={bookingTime === 'previous'}
                >
                  {t('booking.previous')}
                </style.BookingTimeButton>
              )
            }

          </style.BookingTime>

          <style.BookingList size={screenSize}>
            {bookingTime === 'upcoming' ? (
              <List bookList={upcomingBookingList} />
            ) : (
              <List bookList={previousBookingList} />
            )}
          </style.BookingList>
        </style.Booking>
      )}
    </style.Container>
  );
};

export default BookingList;
