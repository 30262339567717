import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import styled from 'styled-components';
import useResponsive from 'hooks/useResponsive';
import MobileMenu from './component/navbar/mobile';

const Application = styled.div`
  height: 100%;
`;
function App() {
  const screenSize = useResponsive();
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(screenSize);
    }
    /* check if in params there's a referer get params */
  }, []);
  return (
    <div className="App">
      {!screenSize.isDesktop && <MobileMenu />}
      <Application>
        <Outlet />
      </Application>
    </div>
  );
}

export default App;
