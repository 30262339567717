import { usePreview } from 'hooks/usePreviews';
import React from 'react';

export const useHairSelectionAndPreviews = (hairs: any) => {
  const [hairSelected, setHairSelected] = React.useState({
    back: null,
    front: null,
    full: null,
    rear: null,
    top: null,
  });
  /* Valeur par défaut pour les cheveux */
  React.useEffect(() => {
    setHairSelected({
      back: hairs.back?.hair[0].idStyleAsset,
      front: hairs.front?.hair[0].idStyleAsset,
      full: null,
      rear: hairs.rear?.hair[0].idStyleAsset,
      top: hairs.top?.hair[5].idStyleAsset,
    });
  }, [
    hairs.back?.hair,
    hairs.front?.hair,
    hairs.full?.hair,
    hairs.rear?.hair,
    hairs.top?.hair,
  ]);

  const getHairPreviewName = () => {
    if (hairSelected.full) {
      return `Style_Assets/Hairs/Previews/${hairSelected.full}_F.jpg`;
    }
    if (
      hairSelected.back
      && hairSelected.front
      && hairSelected.rear
      && hairSelected.top
    ) {
      return `Style_Assets/Hairs/Previews/${hairSelected.back}_${hairSelected.front}_${hairSelected.rear}_${hairSelected.top}_F.jpg`;
    }
    return false;
  };
  /* previews for hair */
  const hairPreviewName = getHairPreviewName();
  const hairPreviewURL = usePreview(hairPreviewName ? [hairPreviewName] : []);

  return {
    hairSelected, setHairSelected, hairPreviewName, hairPreviewURL,
  };
};
