/*   */
import COLORS from 'color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  background-color: ${COLORS.GAINSBORO};
  max-height: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow: scroll;
`;
Container.displayName = 'ContainerDetails';

export const VideoMovie = styled.video<ComponentProps>`
  display: block;
  margin: 0 auto;
  width: ${({ size }) => (size?.isMobile ? '100%' : size?.isTablet ? '70%' : '50%')};
  height: 50%;
  margin-top: ${({ size }) => (size?.isMobile ? '30%' : size?.isTablet ? '30%' : '10%')};
  object-fit: contain;
`;

export const MovieCommands = styled.div<ComponentProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > button {
    margin: 1%;
    padding: ${({ size }) => (size?.isMobile ? '2%' : '1%')}
      ${({ size }) => (size?.isMobile ? '5%' : '2%')};
    background: #eceded;
    border: 1px solid #bcbdbd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    > a {
      text-decoration: none;
      color: black !important;
    }
    cursor: pointer;
  }
  > button:hover {
    background: #bcbdbd;
  }
`;

export const MovieDetails = styled.div<ComponentProps>`
  padding: 2%;
`;

export const MovieTitle = styled.h1<ComponentProps>`
  font-size: 2rem;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  color: ${COLORS.GREY};
`;

export const MovieSubTitle = styled.div<ComponentProps>`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
  display:flex; 
  justify-content: center;
  align-items: center;
  > img {
    margin-left: 0.5rem;
  }
  margin: 2svh 0;
  padding: 0;
  color: ${COLORS.GREY};

  > span {
    color: ${COLORS.BLUE_SILKKE};
  }
`;
