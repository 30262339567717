import React, { useState } from 'react';
import Header from 'component/global/Header/Header';
import Footer from 'component/global/Footer/Footer';
import { useQuery } from 'react-query';
import { getStyleAssetsPreview } from 'providers/style';
import LoadingSpinner from 'component/LoadingSpinner';
import { Grid } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import * as S from './HairSelectionStyle';

type HairPart = 'front' | 'back' | 'top' | 'rear' | 'full';

interface HairSelectionProps {
  selections: any;
  hairs: any;
  step: string;
  setStep: any;
}

const HairSelection: React.FC<HairSelectionProps> = ({
  selections,
  setStep,
  hairs,
  step,
}) => {
  const hairList = hairs.hair;
  const { data, status } = useQuery(['previews'], async () => getStyleAssetsPreview(
    hairList.map((v: any) => ({ path: v.path, libel: v.libel })),
  ));
  const previews = data?.data.preview;
  const { t } = useTranslation();
  const [selected, setSelected] = selections; // Déstructurez selectedState pour obtenir selected et setSelected
  // Ajoutez un nouvel état pour suivre les images sélectionnées
  const [selectedHair, setSelectedHair] = useState<number>(
    selected[step] || hairList[0].idStyleAsset,
  );
  const selectHair = (idStyleAsset: number) => {
    const newSelected = selected;
    newSelected[step] = idStyleAsset;
    newSelected.full = step !== 'full' ? null : selected[step];
    setSelected(newSelected);
    setStep('');
  };
  return (
    <>
      <Header title="NEW" subTitle="digital human" />
      <S.Main>
        {status === 'loading' ? (
          <LoadingSpinner shouldBeCenter />
        ) : (
          <Grid container spacing={3}>
            {hairList.map((hair: any, index: any) => (
              <Grid item xs={6} sm={6} md={3} key={hair.idStyleAsset}>
                <S.ImageWrapper selected={selectedHair === hair.idStyleAsset}>
                  <S.Image
                    src={previews[hair.libel]}
                    alt={hair.libel}
                    onClick={() => {
                      selectHair(hair.idStyleAsset);
                    }}
                  />
                  {selectedHair === hair.idStyleAsset && (
                    <S.SelectedCheck>
                      <DoneIcon style={{ color: 'white' }} />
                    </S.SelectedCheck>
                  )}
                </S.ImageWrapper>
              </Grid>
            ))}
          </Grid>
        )}
        {/*         <Button
          width="auto"
          style={{
            margin: '0',
            position: 'absolute',
            bottom: '5rem',
            right: '3rem',
            zIndex: 10,
          }}
          disabled={false}
          onClick={() => selectHair()}
        >
          {t('digital-human.create.preview.next')}
        </Button> */}
      </S.Main>
      <Footer title={hairs.libel} step={3} totalStep={4} />
    </>
  );
};

export default HairSelection;
