export default {
  'digital-human.create.preview': "let's create your new digital human",
  'digital-human.create.preview.next': 'next',
  'digital-human.create.preview.footer': 'create your digital human',
  'avatar.style.warning':
    'You won&apos;t be able to make another style for this avatar !',
  'avatar.style.confirm': 'Confirm this style ?',
  'avatar.style.confirm.info':
    'A video will be generated with the style you just edited.',
  'avatar.style.success':
    "Your style is building now, you'll receive an email when it's ready",
};
