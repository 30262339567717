import React, { useEffect, useState } from 'react';
import Header from 'component/global/Header/Header';
import COLORS from 'color';
import { useParams } from 'react-router-dom';
import { buildStyle } from 'providers/style';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import Eyes from './eyes/Eyes';
import Hair from './hair/Hair';

const CreateAvatarStyle: React.FC = () => {
  const [globalStep, setGlobalStep] = useState<string>('style');
  const [eyesIds, setEyesIds] = useState<any>();
  const [hairColorsIds, setHairColorsIds] = useState<any>();
  const [hairIds, setHairIds] = useState<any>();
  const [error, setError] = useState<string | null>(null);
  const [mustSkipPreview, setMustSkipPreview] = useState<boolean>(false);

  const { idPicture3D } = useParams();
  const randomKeyString = Math.random().toString(36).substring(7);
  const changeStepHairToStyle = () => {
    setMustSkipPreview(true);
    setGlobalStep('style');
  };
  const stepToComponent: { [K in typeof globalStep]: JSX.Element } = {
    style: <Eyes setEyesIds={setEyesIds} skipPreview={mustSkipPreview} />,
    hair: <Hair setHairColors={setHairColorsIds} setHair={setHairIds} changeStepHairToStyle={changeStepHairToStyle} />,
  };
  const submitBuildYourStyle = () => {
    const idStyleAssets = [...eyesIds, ...hairIds, ...hairColorsIds].map((id: any) => parseInt(id, 10));
    buildStyle(parseInt(idPicture3D!, 10), idStyleAssets).then(() => {
      window.location.href = '/?bsc=1';
    }).catch((err: any) => {
      console.log(err);
      setError(err.response.data.message);
    });
  };
  useEffect(() => {
    if (Array.isArray(eyesIds) && eyesIds.length === 3) {
      setGlobalStep('hair');
    }
    /* every globalStep completed */
    if (
      Array.isArray(eyesIds) && eyesIds.length === 3
      && Array.isArray(hairColorsIds) && hairColorsIds.length === 3
      && Array.isArray(hairIds) && hairIds.length > 0
    ) {
      submitBuildYourStyle();
    }
  }, [eyesIds, hairColorsIds,
    hairIds]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: COLORS.GAINSBORO,
      }}
    >
      {globalStep === 'submission' ? <LoadingSpinner shouldBeCenter /> : stepToComponent[globalStep]}
      {error ? (
        <Notification
          type="error"
          message={error}
          key={randomKeyString}
        />
      ) : null}
    </div>
  );
};

export default CreateAvatarStyle;
