import React, { useState, useEffect } from 'react';
import Footer from 'component/global/Footer/Footer';
import preview from 'assets/img/create_style_preview.png';
import Button from 'component/Button';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { checkOwner } from 'providers/style';
import { useParams } from 'react-router-dom';
import { logout } from 'providers/auth';
import LoadingSpinner from 'component/LoadingSpinner';
import Header from 'component/global/Header/Header';
import * as S from './EyesStyle';
import AssetsBuilder from './builder/EyesBuilder';

const GetStarted: React.FC<{
  setDisplayPreview: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setDisplayPreview }) => {
  const responsive = useResponsive();
  const { t } = useTranslation();
  return (
    <S.GetStartedContainer>
      <Header title="NEW" subTitle="digital human" />
      <S.Main>
        <S.PreviewImageWrapper>
          <S.PreviewImage src={preview} alt="digital human background" />
          <S.PreviewTextCoveringImage>
            {t('digital-human.create.preview')}
          </S.PreviewTextCoveringImage>
        </S.PreviewImageWrapper>
        <S.Action size={responsive}>
          <Button
            height="5vh"
            width={responsive.isDesktop ? '10%' : undefined}
            style={{ marginTop: '4%' }}
            disabled={false}
            onClick={() => setDisplayPreview(false)}
          >
            {t('digital-human.create.preview.next')}
          </Button>

        </S.Action>
      </S.Main>
      <Footer title={t('digital-human.create.preview.footer')} step={1} totalStep={3} />
    </S.GetStartedContainer>
  );
};

const Eyes: React.FC<{
  setEyesIds: React.Dispatch<React.SetStateAction<[]>>;
  skipPreview?: boolean;
}> = ({ setEyesIds, skipPreview = false }) => {
  const { idPicture3D } = useParams<{ idPicture3D: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [displayPreview, setDisplayPreview] = useState<boolean>(true);
  useEffect(() => {
    if (idPicture3D) {
      // eslint-disable-next-line radix
      checkOwner(parseInt(idPicture3D)).then(() => setLoading(false));
    }
  }, []);

  if (!loading) {
    if (displayPreview && !skipPreview) {
      return <GetStarted setDisplayPreview={setDisplayPreview} />;
    }
    return <AssetsBuilder setEyesIds={setEyesIds} />;
  }
  return <LoadingSpinner shouldBeCenter />;
};
Eyes.defaultProps = {
  skipPreview: false,
};
export default Eyes;
