import { request } from './request';

export const getUserCollection = async () => {
  const collection = await request(true).get('/collection/user');
  return collection;
};

export const getAvatarPreview = async (idPicture3D: string) => {
  const avatar = await request(true).get(
    `/collection/preview?idPicture3D=${idPicture3D}`,
  );
  return avatar;
};
