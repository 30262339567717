const COLORS = {
  BLUE_SILKKE: 'rgba(69, 78, 206, 1)',
  BLUE_SILKKE_DARK: 'rgba(69, 78, 206, 0.8)',
  GAINSBORO: 'rgba(241, 242, 242, 1)',
  GAINSBORO_TRANSPARENT: 'rgba(241, 242, 242, 0.95)',
  GAINSBORO_DARK: 'rgb(197 202 202 / 80%)',
  GREY: 'rgba(171, 172, 172, 1)',
  RED_LIGHT: 'rgb(194, 116, 116)',
};

export default COLORS;
