/*   */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';

export const Container = styled.div<ComponentProps>`
  height: 100%;
  width: 100%;
  background: ${COLORS.GAINSBORO};
`;

export const Booking = styled.div<ComponentProps>`
  width: 100%;
  height: 74%;
`;

export const BookingTitle = styled.h3<ComponentProps>`
  font-size: 1.5rem;
  padding-top: ${({ size }) => (size?.isMobile ? '5%' : '2%')};
  font-family: "Urbanist", sans-serif;
  text-align: center;
`;

export const BookingInfo = styled.span<ComponentProps>`
  font-size: 1rem;
  font-family: "Urbanist", sans-serif;
  display: block;
  text-align: center;
  margin: ${({ size }) => (size?.isMobile ? '4%' : '2%')} 0;
  color: ${COLORS.GREY};
`;

export const BookingQrCode = styled.img<ComponentProps>`
  text-align: center;
  display: block;
  max-width: ${({ size }) => (size?.isMobile ? '50vw' : '10vw')};
  margin: 0 auto;
`;

export const BookingDownload = styled.a<ComponentProps>`
  display: block;
  text-align: center;
  margin: 0 auto;
  width: ${({ size }) => (size?.isDesktop ? '15%' : '55%')};
  padding: ${({ size }) => (size?.isDesktop ? '1%' : '2%')};
  border-radius: 5px;
  background: ${COLORS.BLUE_SILKKE};
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "Urbanist", sans-serif;
  &:hover {
    background: ${COLORS.BLUE_SILKKE_DARK};
  }
`;
