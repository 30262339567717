import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/css/reset.css';
import './assets/css/index.css';
import 'react-calendar/dist/Calendar.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import { I18nextProvider } from 'react-i18next';
import ErrorBoundary from './ErrorBoundary';
import lang from './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const urlParams = new URLSearchParams(window.location.search);
const referer = urlParams.get('referer');
if (referer) {
  localStorage.setItem('referer', referer);
}
const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={lang}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </I18nextProvider>
  </QueryClientProvider>,
);
