import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';
import { ScreenSize } from 'type/ScreenSize';
import colors from '../../color';

interface MenuProps {
  isOpen: boolean;
  size: ScreenSize;
}
export const BurgerIcon = styled.div<MenuProps>`
  position: fixed;
  top: 5%;
  right: 10%;
  z-index: 9999;
  width: ${({ size }) => (size?.isMobile || size?.isTablet ? '8vw' : '20vw')};
  height: 3%;
  display: ${({ isOpen }) => (isOpen ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
    & > div {
    width: 100%;
    height: 5%;
    font-weight: bold;
    background-color: ${colors.BLUE_SILKKE};
    transition: all 0.5s ease;
    position: relative;
    transform-origin: 1px;
  }
`;

export const List = styled.div<MenuProps>`
  position: fixed;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  left: 0;
  width: ${({ size }) => (size.isMobile || size.isTablet ? '100%' : '50%')};

  ${({ size }) => !size.isMobile && !size.isTablet && 'left: 25%;'}
  ${({ size }) => !size.isDesktop && 'padding: 7% 0;'}
  background: #F1F2F2CC;

  z-index: 9999 !important;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${({ isOpen }) => (isOpen ? '100' : '-1')};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(100%)')};

  box-shadow: 0 0 0 0.5px #000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
export const Overlay = styled.div<MenuProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 50;
`;

export const Line = styled.div`
  cursor: pointer;
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  border-bottom: 1px solid #000;
`;

export const SubListItem = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
export const SubListComponent = styled.li`
  list-style: none;
`;
export const Delimiter = styled.div<ComponentProps>`
  width: ${({ size }) => (size?.isDesktop ? '50%' : '75%')};
  margin: 0 auto;
  height: 0.5px;
  background: rgba(201, 202, 202, 1);
`;
export const SubListLink = styled(Link)<{
  active: string;
  variant?: string;
  size: ScreenSize;
}>`
  font-family: "Urbanist", sans-serif;
  text-transform: capitalize;
  text-decoration: none;
  ${({ active }) => (active === 'true' ? 'font-weight: bold;' : 'font-weight: 300;')}
  font-size: ${({ size }) => (size.isTablet ? '2.2rem' : '1.3rem')};
  ${({ size }) => (!size?.isDesktop && 'margin: 2% 0;') || 'margin: 1% 0;'}
  color: ${({ variant }) => (variant === 'red'
    ? 'red'
    : variant === 'blue'
      ? colors.BLUE_SILKKE
      : 'black')};
  & span {
    text-decoration: underline;
  }
  margin: 5.5% 0;
`;

export const SubMenu = styled.ul`
  height: 50%;
  width: 70%;
`;
