import COLORS from 'color';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Main = styled.div`
  height: 74.5%;
  overflow: scroll;
`;

export const ImageWrapper = styled.div<{ selected?: boolean }>`
  display: block;
  margin: 0 auto;
  cursor: pointer;

  ${({ selected }) => selected && 'transform: scale(0.9); transition: transform 0.05s ease;'}
  position: relative;
`;

export const SelectedCheck = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: ${COLORS.BLUE_SILKKE};
  border: 0.2rem solid white;
`;
export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 2%;
  object-fit: contain;
`;
