import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import Button from 'component/Button';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import { useTranslation } from 'react-i18next';

import useResponsive from 'hooks/useResponsive';

import {
  getUserDetails,
  editUserDetails,
  editUserPassword,
  editUserLanguage,
} from 'providers/user';
import * as style from './AccountDetailsStyle';

interface User {
  firstName: string;
  lastName: string;
  birthDate: string;
  address: string;
  addressComplement?: string;
  zipCode: string;
  idCountry: number;
  city: string;
  email: string;
  idLang: number;
}

interface DetailsAccountProps {
  user: User;
  countries: any[];
  langs: any[];
}
const DetailsAccount: React.FC<DetailsAccountProps> = ({
  user,
  countries,
  langs,
}: DetailsAccountProps) => {
  const { t, i18n } = useTranslation();

  const screenSize = useResponsive();
  const infoRef = React.useRef<string>('');
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      birthDate: user.birthDate || '',
      address: user.address || '',
      complement: user.addressComplement || '',
      zipCode: user.zipCode || '',
      country:
        countries
        && countries.length > 0
        && countries.filter((country: any) => {
          if (user.idCountry && user.idCountry !== 0) {
            return country.idCountry === user.idCountry;
          }
          return country.libel === 'France';
        })[0].iso,
      city: user.city || '',
      idLang:
        langs.length > 0
        && langs.filter((lang: any) => lang.idLang === user.idLang)[0].idLang,
    },
  });
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  const randomKeyString = Math.random().toString(36).substring(7);
  const sendEditAccount = async (formData: any) => {
    setUpdateLoading(true);

    try {
      await editUserDetails(formData);
      infoRef.current = t('user.details.updated');

      setUpdateLoading(false);
      // mettre un message succes
    } catch (error) {
      setUpdateLoading(false);
    }
    const newLanguage = parseInt(formData.idLang, 10) === 2 ? 'fr' : 'en';
    localStorage.setItem('lang', newLanguage);
    i18n.changeLanguage(newLanguage);
  };
  useEffect(() => {
    reset({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      birthDate: user.birthDate || '',
      address: user.address || '',
      complement: user.addressComplement || '',
      zipCode: user.zipCode || '',
      country:
        countries
        && countries.length > 0
        && countries.filter((country: any) => {
          if (user.idCountry && user.idCountry !== 0) {
            return country.idCountry === user.idCountry;
          }
          return country.libel === 'France';
        })[0].iso,
      city: user.city || '',
      idLang:
        langs.length > 0
        && langs.filter((lang: any) => lang.idLang === user.idLang)[0].idLang,
    });
  }, [user, reset]);
  useEffect(() => {
    if (errors) {
      const firstError = Object.values(errors)[0];
      infoRef.current = firstError?.message?.toString() || '';
    }
  }, [errors]);
  return (
    <div
      style={screenSize.isMobile ? { marginTop: '5%' } : { marginTop: '1%' }}
    >
      <style.Form size={screenSize} onSubmit={handleSubmit(sendEditAccount)}>
        <Grid container spacing={2} style={{ padding: '3%' }}>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('firstName')}
              placeholder={t('register.firstname') || ''}
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('lastName')}
              placeholder={t('register.lastname') || ''}
              required
            />
          </Grid>

          <Grid item xs={12} xl={12}>
            <style.FormInput
              placeholder={t('register.mail') || ''}
              value={user.email || ''}
              disabled
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('birthDate')}
              required
              placeholder={t('complete.register.birthdate') || ''}
              type="date"
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('address')}
              required
              placeholder={t('complete.register.address') || ''}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('complement')}
              placeholder={t('complete.register.complement') || ''}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('zipCode')}
              placeholder={t('complete.register.postalCode') || ''}
              required
              type="number"
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormInput
              {...register('city')}
              placeholder={t('complete.register.city') || ''}
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <style.FormSelect {...register('country')} required>
              <option value="" disabled>
                {t('complete.register.country') || ''}
              </option>
              {countries.length > 0
                && countries.map((country: any) => (
                  <option key={country.idCountry} value={country.iso}>
                    {country.libel}
                  </option>
                ))}
            </style.FormSelect>
          </Grid>

          <Grid item xs={12} xl={12}>
            {updateLoading ? (
              <LoadingSpinner />
            ) : (
              <Button
                width="auto"
                height="2.5rem"
                style={{ marginTop: '4%', padding: '0 1.5rem' }}
                disabled={false}
              >
                {t('complete.register.save')}
              </Button>
            )}
          </Grid>
        </Grid>

        {infoRef.current !== '' && (
          <Notification type="info" key={randomKeyString} message={infoRef.current} />
        )}
      </style.Form>
    </div>
  );
};

const LanguageForm: React.FC<any> = ({
  user,
  countries,
  langs,
}) => {
  const randomKeyString = Math.random().toString(36).substring(7);
  const { t, i18n } = useTranslation();
  const infoRef = React.useRef<string>('');
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {

      idLang:
        langs.length > 0
        && langs.filter((lang: any) => lang.idLang === user.idLang)[0].idLang,
    },
  });
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  const sendEditLanguage = async (formData: any) => {
    setUpdateLoading(true);

    try {
      await editUserLanguage(formData);
      infoRef.current = t('user.details.updated');

      setUpdateLoading(false);
      // mettre un message succes
    } catch (error) {
      setUpdateLoading(false);
    }
    const newLanguage = parseInt(formData.idLang, 10) === 2 ? 'fr' : 'en';
    localStorage.setItem('lang', newLanguage);
    i18n.changeLanguage(newLanguage);
  };
  useEffect(() => {
    reset({

      idLang:
        langs.length > 0
        && langs.filter((lang: any) => lang.idLang === user.idLang)[0].idLang,
    });
  }, [user, reset]);
  const screenSize = useResponsive();
  return (
    <style.Form size={screenSize} onSubmit={handleSubmit(sendEditLanguage)}>
      <style.Title style={{ paddingBottom: '2%' }}>
        {t('settings.accountDetails.language.title')}
      </style.Title>
      <Grid container>
        <Grid item xs={12} xl={12}>

          <style.FormSelect {...register('idLang')} required>

            {langs.length > 0
              && langs.map((lang: any) => (
                <option key={lang.idLang} value={lang.idLang}>
                  {lang.libel}
                </option>
              ))}
          </style.FormSelect>
        </Grid>
        <Grid item xs={12} xl={12}>
          {updateLoading ? (
            <LoadingSpinner />
          ) : (
            <Button
              width="auto"
              height="2.5rem"
              style={{ marginTop: '4%', padding: '0 1.5rem' }}
              disabled={false}
            >
              {t('complete.register.save')}
            </Button>
          )}
        </Grid>
        {infoRef.current !== '' && (
          <Notification type="info" key={randomKeyString} message={infoRef.current} />
        )}
      </Grid>
    </style.Form>
  );
};
const PasswordAccount: React.FC<any> = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const infoRef = React.useRef<string>('');
  const randomKeyString = Math.random().toString(36).substring(7);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (errors) {
      const firstError = Object.values(errors)[0];
      infoRef.current = firstError?.message?.toString() || '';
    }
  }, [errors]);

  const sendEditPassword = async (formData: any) => {
    setUpdateLoading(true);
    editUserPassword(formData)
      .then((response) => {
        infoRef.current = t('user.details.updated');
        setUpdateLoading(false);
      })
      .catch((error) => {
        infoRef.current = t(error.response.data.message);
        setUpdateLoading(false);
      });
  };
  return (
    <div style={screenSize.isMobile ? { marginTop: '5%' } : {}}>
      <style.Title>
        {t('settings.accountDetails.editPasswordTitle')}
      </style.Title>
      <style.Form size={screenSize} onSubmit={handleSubmit(sendEditPassword)}>
        <Grid container spacing={2} style={{ padding: '3%' }}>
          <Grid item xs={12} xl={12}>
            <style.FormInput
              placeholder={t('settings.accountDetails.oldPassword') || ''}
              type="password"
              {...register('oldPassword')}
              required
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <style.FormInput
              {...register('password')}
              placeholder={t('complete.register.password') || ''}
              required
              type="password"
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <style.FormInput
              {...register('password_confirmation')}
              placeholder={t('complete.register.confirmPassword') || ''}
              required
              type="password"
            />
          </Grid>

          <Grid item xs={12} xl={12}>
            {updateLoading ? (
              <LoadingSpinner />
            ) : (
              <Button
                width="auto"
                height="2.5rem"
                style={{ marginTop: '4%', padding: '0 1.5rem' }}
                disabled={false}
              >
                {t('complete.register.save')}
              </Button>
            )}
          </Grid>
        </Grid>

        {infoRef.current !== '' && (
          <Notification type="info" key={randomKeyString} message={infoRef.current} />
        )}
      </style.Form>
    </div>
  );
};
export const AccountDetails: React.FC = () => {
  const screenSize = useResponsive();
  const [countries, setCountries] = React.useState<any[]>([]);
  const [langs, setLangs] = React.useState<any[]>([]);

  const [user, setUser] = React.useState<any>({});
  const userDetails = useQuery('getUserDetailsQuery', async () => getUserDetails());
  useEffect(() => {
    if (userDetails.data?.data) {
      setLangs(userDetails.data.data.lang);
      setCountries(userDetails.data.data.country);
      setUser(userDetails.data.data.user);
      if (!userDetails.data.data.user.idCountry) {
        /* french default country */
        setUser((prevUser: any) => ({ ...prevUser, idCountry: 76 }));
      }
    }

    if (userDetails.status === 'error') {
      window.location.href = '/login?error=userDetailsError';
    }
  }, [userDetails.data, userDetails.status]);
  return (
    <style.Container>
      {userDetails.isLoading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <style.Main>
          <DetailsAccount user={user} countries={countries} langs={langs} />
          <LanguageForm user={user} countries={countries} langs={langs} />
          <PasswordAccount />
        </style.Main>
      )}
    </style.Container>
  );
};

export default AccountDetails;
