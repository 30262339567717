/* eslint-disable react/destructuring-assignment */
import { Component, ErrorInfo, ReactNode } from 'react';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV === 'development') {
      console.log(error, errorInfo);
    } else {
      alert(error.toString());
      alert(errorInfo.toString());
    }
  }

  render() {
    if (this.state.hasError) {
      return null; // ou peut être un message ou une page d'erreur personnalisée
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
