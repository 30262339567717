export default {
  'global.confirm': 'Confirmer',
  'global.next': 'Suivant',
  'global.previous': 'Précédent',
  'global.back': 'Retour',
  'global.cancel': 'Annuler',
  'global.backHome': "Retour à l'accueil",
  'global.close': 'Fermer',
  'global.seeMore': 'Charger plus',
};
