export default {
  'title.collection': 'silkke - Collection',
  'title.movie': 'silkke - Movies',
  'title.movieDetails': 'silkke - Movie',
  'title.fastPass': 'silkke - Fast pass',
  'title.login': 'silkke - Login',
  'title.preregister': 'silkke - Pre-registration',
  'title.register': 'silkke - Registration',
  'title.resetPassword': 'silkke - Forgotten password',
  'title.dateBooking': 'silkke - Booking date',
  'title.yourBooking': 'silkke - Your booking',
  'title.bookingList': 'silkke - Booking list',
  'title.freeBooking': 'silkke - Free booking',
  'title.payment': 'silkke - Payment',
  'title.capsuleChoice': 'silkke - Capsule choice',
  'title.settings': 'silkke - Settings',
};
