/*   */
import styled from 'styled-components';
import BackgroundPreRegisterDesktop from 'assets/img/background_register_desktop.jpg';
import { Link } from 'react-router-dom';
import COLORS from 'color';
import { ComponentProps } from 'type/ComponentProps';

export const SilkkeLogo = styled.img<ComponentProps>`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto !important;
`;

export const Background = styled.div<ComponentProps>`
  background: ${({ size }) => (size?.isDesktop
    ? `url(${BackgroundPreRegisterDesktop})`
    : 'linear-gradient(180deg, #454ece 0%, #bb7cb7 100%)')};
  min-height: 100%;
  background-size: cover;
  width: 100vw;
  position: relative;
`;

export const Form = styled.form<ComponentProps>`
  background-color: ${COLORS.GAINSBORO};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: ${({ size }) => (size?.isMobile || size?.isTablet ? '85%' : '25%')};
  border-radius: 10px;
`;

export const FormInput = styled.input<ComponentProps>`
  background: rgba(255, 255, 255, 0.07);
  border: none;
  height: 2.2rem;
  width: 70%;
  border: 1px solid ${COLORS.GREY};
  display: block;

  margin: 0 auto;
  padding: 0 1rem;
  ::placeholder {
    color: rgba(201, 202, 202, 1);
  }
`;
export const FormCheckBoxGroup = styled.div<ComponentProps>`
  margin: 0 auto;
  color: ${COLORS.GREY};
  display: flex;
  align-items: center;
  > label {
    font-family: "Urbanist", sans-serif;
    > a {
      color: ${COLORS.BLUE_SILKKE};
    }
  }
`;

export const FormGroupInput = styled.div<ComponentProps>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 20px;
`;

export const FormHeader = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 10% 0 0;
  padding-bottom: ${({ size }) => (size?.isTablet ? '5%' : '0')};
  > * {
    margin: 1rem 0;
  }
`;

export const FormHeaderTitle = styled.h1<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 1.5rem;
  color: ${COLORS.GREY};
`;

export const FormError = styled.div<ComponentProps>`
  color: ${COLORS.RED_LIGHT};
  font-family: "Urbanist", sans-serif;
  text-align: center;
  margin-top: 5%;
`;

export const SubLink = styled(Link)<any>`
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.BLUE_SILKKE};
  text-align: center;
  & hover {
    text-decoration: underline;
  }
  display: block;
  margin-top: 3rem;
  font-size: ${({ fontSize }) => fontSize || '1rem'};
`;

export const FormBottom = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin: ${({ size }) => (size?.isTablet ? '12% auto' : size?.isDesktop ? '20% auto' : '8% auto')};
`;
