import {
  Alert, AlertColor, IconButton, Snackbar,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Notification: React.FC<{ message: string, type: AlertColor }> = ({ message, type }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      action={action}
      sx={{ maxWidth: '99svw' }}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

export default Notification;
