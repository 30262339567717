import COLORS from 'color';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${COLORS.GAINSBORO};
`;

export const Main = styled.div<ComponentProps>`
  height: 74.5%;
`;
