import COLORS from 'color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div<ComponentProps>`
  padding-top: ${({ size }) => (size?.isMobile ? '5%' : '0%')};
  height: 100%;
  width: 100%;
  background: ${COLORS.GAINSBORO};
`;

export const Title = styled.h3<ComponentProps>`
  font-size: 1.5rem;
  padding-top: 2%;

  font-family: "Urbanist", sans-serif;
  text-align: center;
`;

export const SubTitle = styled.h4<ComponentProps>`
  font-size: 1.2rem;

  font-family: "Urbanist", sans-serif;
  text-align: center;
  padding-top: 2%;
  color: ${COLORS.GREY};
`;

export const Company = styled.img<ComponentProps>`
  text-align: center;
  display: block;
  margin: ${({ size }) => (size?.isMobile ? '15%' : '3%')} auto;
  padding: ${({ size }) => (size?.isMobile ? '10%' : '2%')};
  width: ${({ size }) => (size?.isMobile ? '30%' : '10%')};
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background: white;
`;

export const Button = styled.button<ComponentProps>`
  background: ${COLORS.BLUE_SILKKE};
  color: white;
  font-size: ${({ size }) => (size?.isMobile ? '1.5rem' : '1.2rem')};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: block;
  margin: 2% auto;
  border: none;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  &:hover {
    background: ${COLORS.BLUE_SILKKE_DARK};
  }
  transition: background 0.2s ease;
`;

export const InfoCompany = styled(Title)<ComponentProps>`
  ${({ size }) => (size?.isMobile ? 'padding: 5%;' : '')}
`;
