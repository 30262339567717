import COLORS from 'color';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.GAINSBORO};
`;

export const Title = styled.h1`
  color: ${COLORS.BLUE_SILKKE};
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  margin: 5% 0;
`;

export const Form = styled.form<ComponentProps>`
  height: 80%;
  ${({ size }) => size?.isMobile && 'margin-top: 10%;'}
  width: 90%;
`;

export const FormInput = styled.input<any>`
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border: none;
  height: 2.2rem;
  width: ${({ size }) => (size?.isMobile ? '65%' : '30%')};

  border-radius: 5px;
  display: block;
  margin: 0 auto;
  padding: 0 2rem;
  ::placeholder {
    color: rgba(201, 202, 202, 1);
  }
`;

export const FormGroupInput = styled.div<ComponentProps>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 20px;
`;

export const FormButton = styled.button`
  /* text must  break when too long text */
  white-space: break-spaces;
  background: ${COLORS.BLUE_SILKKE};
  border: none;
  border-radius: 14px;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.7rem;
  align-items: center;
  margin: 0 auto;
  display: block;
  margin-top: 2rem;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease-in-out;
  &:hover {
    background: ${COLORS.BLUE_SILKKE};
    transform: scale(0.98);
  }
`;
