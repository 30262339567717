import { request } from './request';

export const getUserMovies = async (page: number) => {
  const movies = await request(true).get(`/movie/user?page=${page}`);
  return movies;
};

export const getUserMovie = async (idMovie: string, idPicture3D: string) => {
  const movie = await request(true).get(
    `/movie/user/${idMovie}/${idPicture3D}`,
  );
  return movie;
};

export const deleteUserMovie = async (idMovie: string, idPicture3D: string) => {
  const movie = await request(true).delete(
    `/movie/user/${idMovie}/${idPicture3D}`,
  );
  return movie;
};
