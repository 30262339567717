export type NavItems = Array<
Array<{ name: string; path: string; logout?: boolean; variant?: string }>
>;

export default [
  [
    {
      name: 'navbar.collection',
      path: '/avatar/collection',
      variant: '',
    },
  ],
  [
    {
      name: 'navbar.movie',
      path: '/movie',
      variant: '',
    },
  ],
  [
    {
      name: 'navbar.build',
      path: '/booking',
      variant: 'blue',
    },
    {
      name: 'navbar.account',
      path: '/account/details',
      variant: '',
    },
    {
      name: 'navbar.logout',
      path: '/logout',
      variant: 'red',
    },
  ],
];
