import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useQuery } from 'react-query';
import Button from 'component/Button';
import Header from 'component/global/Header/Header';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import useResponsive from 'hooks/useResponsive';
import { checkIfUserExistByKey, completeRegister } from 'providers/auth';

import { useTranslation } from 'react-i18next';
import * as style from './RegisterStyle';

export const Register: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('title.register');
  }, []);
  const [keyError, setKeyError] = React.useState<string | null>('');
  const screenSize = useResponsive();
  const [firstName, setFirstname] = React.useState<string>('');
  const [lastName, setLastname] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [registrationLoading, setRegistrationLoading] = React.useState<boolean>(false);
  const [countries, setCountries] = React.useState<any[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>('');
  const userCheckQuery = useQuery('checkUserByKey', async () => {
    if (keyError === '') {
      return checkIfUserExistByKey();
    }
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      password: '',
      password_confirmation: '',
      birthDate: '',
      phone: '',
      address: '',
      complement: '',
      zipCode: '',
      country: '',
      city: '',
    },
  });

  const randomKeyString = Math.random().toString(36).substring(7);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const keyParam = searchParams.get('key');

    if (!keyParam) {
      setKeyError(t('complete.register.keyMissing'));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (userCheckQuery.data) {
      setCountries(userCheckQuery.data.data.allCountry);
      setFirstname(userCheckQuery.data.data.user.firstName);
      setLastname(userCheckQuery.data.data.user.lastName);
      setEmail(userCheckQuery.data.data.user.email);
    }

    if (userCheckQuery.status === 'error') {
      window.location.href = '/login?error=userKeyNotFound';
    }
  }, [userCheckQuery.data, userCheckQuery.status]);
  useEffect(() => {
    reset({
      firstName,
      lastName,
      password: '',
      password_confirmation: '',
      birthDate: '',
      phone: '',
      address: '',
      complement: '',
      zipCode: '',
      country: '',
      city: '',
    });
  }, [firstName, lastName, email, reset]);
  useEffect(() => {
    if (errors) {
      const firstError = Object.values(errors)[0];
      setErrorMessage(firstError?.message?.toString() || '');
    }
  }, [errors]);
  const sendRegistration = async (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { password_confirmation, password } = formData;

    if (password !== password_confirmation) {
      setErrorMessage(t('complete.register.passwordNotMatch'));
      return;
    }
    setRegistrationLoading(true);
    completeRegister(formData)
      .then(() => {
        window.location.href = '/login?message=register';
      })
      .catch((err) => {
        setRegistrationLoading(false);
        setErrorMessage(err.response.data.message);
      });
  };
  return keyError === '' ? (
    <style.Container>
      <Header title={t('complete.register.title')} subTitle="" />
      {userCheckQuery.isLoading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <style.Main>
          <style.Form
            size={screenSize}
            onSubmit={handleSubmit(sendRegistration)}
          >
            {/* grid container with custom padding */}
            <Grid container spacing={2} style={{ padding: '3%' }}>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('firstName')}
                  placeholder={t('register.firstname') || ''}
                  required
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('lastName')}
                  placeholder={t('register.lastname') || ''}
                  required
                />
              </Grid>

              <Grid item xs={12} xl={12}>
                <style.FormInput
                  placeholder={t('register.mail') || ''}
                  value={email}
                  disabled
                  type="email"
                  required
                />
              </Grid>

              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('password')}
                  placeholder={t('complete.register.password') || ''}
                  required
                  type="password"
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('password_confirmation')}
                  placeholder={t('complete.register.confirmPassword') || ''}
                  required
                  type="password"
                />
              </Grid>

              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('birthDate')}
                  required
                  placeholder={t('complete.register.birthdate') || ''}
                  type="date"
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('phone')}
                  placeholder={t('complete.register.phone') || ''}
                  required
                  type="tel"
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('address')}
                  required
                  placeholder={t('complete.register.address') || ''}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('complement')}
                  placeholder={t('complete.register.complement') || ''}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('zipCode')}
                  placeholder={t('complete.register.postalCode') || ''}
                  required
                  type="number"
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormSelect
                  {...register(
                    'country', /* , { validate: (value) => value !== t('complete.register.country') || t('complete.register.chooseCountryError') } */
                  )}
                  required
                >
                  <option value="" disabled selected>
                    {t('complete.register.country') || ''}
                  </option>
                  {countries.length > 0
                    && countries.map((country) => (
                      <option key={country.idCountry} value={country.iso}>
                        {country.libel}
                      </option>
                    ))}
                </style.FormSelect>
              </Grid>
              <Grid item xs={12} xl={6}>
                <style.FormInput
                  {...register('city')}
                  placeholder={t('complete.register.city') || ''}
                  required
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                {registrationLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Button
                    width={screenSize.isTablet ? '60%' : '80%'}
                    height="2.5rem"
                    style={{ marginTop: '4%' }}
                    disabled={false}
                  >
                    {t('complete.register.save')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </style.Form>
          {errorMessage && errorMessage.length > 0 && (
            <Notification type="error" message="error" key={randomKeyString} />
          )}
        </style.Main>
      )}
    </style.Container>
  ) : (
    <Notification
      type="error"
      message={
        keyError
        || (userCheckQuery.status === 'error' ? 'Utilisateur invalide' : '')
      }
    />
  );
};

export default Register;
