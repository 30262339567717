/*   */
import COLORS from 'color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  background-color: ${COLORS.GAINSBORO};
  max-height: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow: scroll;
`;

export const ContainerMovie = styled.div<ComponentProps>`
  background-color: ${COLORS.GAINSBORO};
  overflow: scroll;
  height: 80%;
  min-width: 100%;
  max-width: 100%;
`;
Container.displayName = 'ContainerDetails';

export const MovieCardContainer = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  margin: 2% auto;
  padding: 0 0 0 0;
  width: 90%;
  height: ${({ size }) => (size?.isMobile ? '20%' : '50%')};
`;
MovieCardContainer.displayName = 'MovieCardContainer';

export const MovieCardVideo = styled.video<ComponentProps>`
  width: 60%;
  height: 100%;
  border-radius: 5px 0 0 5px;
  object-fit: contain;
`;
MovieCardVideo.displayName = 'MovieCardVideo';

export const MovieCardDetails = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2%;
  width: 40%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
`;

export const MovieCardTitle = styled.h1<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  word-wrap: break-word;

  font-size: ${({ size }) => (size?.isMobile ? '1.2rem' : '2.5rem')};
`;

export const MovieCardEvent = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  font-size:  ${({ size }) => (size?.isMobile ? '1.5rem' : '2rem')};
  word-wrap: break-word;
  color: grey;
  
  margin-top 1%;
`;
export const MovieCardDate = styled.div<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  font-size:  ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
  word-wrap: break-word;
  color: ${COLORS.GREY};
  
  margin-top 1%;
`;
