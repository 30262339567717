import { useState, useEffect, useMemo } from 'react';
import { getStyleAssetsPreview } from 'providers/style';

export const usePreview = (previewsInput: Array<string>) => {
  const [previews, setPreviews] = useState(previewsInput);
  const [previewsUrl, setPreviewsUrl] = useState('');
  useEffect(() => {
    /* check if fetch is needed */
    if (JSON.stringify(previewsInput) !== JSON.stringify(previews)) {
      setPreviews(previewsInput);
    }
  }, [previewsInput]);
  useEffect(() => {
    const fetchPreviewUrl = () => {
      getStyleAssetsPreview(previews).then((response) => {
        setPreviewsUrl(response.data.preview);
      });
    };

    if (previews.length > 0) {
      fetchPreviewUrl();
    }
  }, [previews]);

  return previewsUrl;
};
