import axios from 'axios';
import removeRedirectionsData from 'utils/removeRedirectionsData';

export const API_URL = process.env.REACT_APP_API_URL;
export const cancelAPI = new AbortController();

export const request = (mustBeLoggedIn = false, customHeader?: any) => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (mustBeLoggedIn) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (customHeader) {
    Object.assign(headers, customHeader);
  }

  const instance = axios.create({
    baseURL: API_URL,
    signal: cancelAPI.signal,
    headers,
  });

  // Ajout d'un intercepteur de réponse
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 403) {
        const probableSavedUrl = localStorage.getItem('surl');
        if (probableSavedUrl === null) {
          const path = window.location.pathname;
          localStorage.setItem('surl', path);
        } else {
          localStorage.removeItem('surl');
        }
        window.location.href = '/login?error=unauthorized';
      } else if (
        error.response.status === 401
        && window.location.pathname !== '/login'
      ) {
        // C'est une 401 et on n'est pas déjà sur la page de login, on redirige vers la page de login
        window.location.href = '/login';
      }

      // On laisse l'erreur passer pour qu'elle puisse être gérée ailleurs
      return Promise.reject(error);
    },
  );

  return instance;
};
