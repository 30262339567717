import styled from 'styled-components';
import COLORS from 'color';
import { ScreenSize } from 'type/ScreenSize';

export const GetStartedContainer = styled.div`
  height: 100%;
`;
export const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
`;

export const PreviewTextCoveringImage = styled.div`
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Urbanist", sans-serif;
  font-weight: 900;
  font-size: 2.25rem;
  color: ${COLORS.BLUE_SILKKE};
  text-align: center;
`;

export const PreviewImageWrapper = styled.div`
  position: relative;
  width: max-content;
  max-height: 74.5%;
  height: 74.5%;
  padding-top: 1rem;
  margin: 0 auto;
`;

export const Action = styled.div<{ size: ScreenSize }>`
  ${({ size }) => size.isMobile && 'padding-top:1rem;'}
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin: 0 auto;
`;
export const Main = styled.div`
  height: 74.5%;
`;
