import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import Back from '../assets/img/back.png';

const LinkBack = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  padding: 1rem;
  z-index: 100;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease;
`;
const BackButton: React.FC<{ to: string }> = ({ to }) => (
  <LinkBack to={to}>
    <img src={Back} alt="back button" />
  </LinkBack>
);

export default BackButton;
