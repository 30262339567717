/*   */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';
import { ScreenSize } from 'type/ScreenSize';
import homeMobile from 'assets/img/home_mobile.jpeg';
import homeDesktop from 'assets/img/background_login_desktop.jpg';

export const SilkkeLogo = styled.img<ComponentProps>`
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  margin: 0 auto !important;
  ${({ size }) => size?.isMobile && 'position: fixed;'}
  ${({ size }) => size?.isMobile && 'top: 3rem;'}
`;

export const Background = styled.div<ComponentProps>`
  background: ${({ size }) => (size?.isMobile ? `url(${homeMobile})` : `url(${homeDesktop})`)};
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Menu = styled.div<ComponentProps>`
  background-color: ${({ size }) => (size?.isMobile ? '#F1F2F2CC' : '#F1F2F2')};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ size }) => (size?.isDesktop ? '2%' : '4%')} 0;
  padding-top: ${({ size }) => (size?.isMobile ? '0%' : '3%')};
  ${({ size }) => size?.isMobile && 'position: fixed;'}
  ${({ size }) => size?.isMobile && 'bottom: 0;'}
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: ${({ size }) => (size?.isMobile || size?.isTablet ? '100%' : '20%')};
  border-radius: 10px;
  ${({ size }) => (size?.isMobile || size?.isTablet)
    && `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

export const SubListLink = styled(Link)<{
  variant?: String;
  size: ScreenSize;
  isActive?: Boolean;
}>`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : '300')};
  font-size: ${({ size }) => (size.isTablet ? '2.2rem' : '1.3rem')};
  margin: 8% 0;
  text-align: center;
  color: ${({ variant }) => (variant === 'alert'
    ? 'red'
    : variant === 'blue'
      ? COLORS.BLUE_SILKKE
      : 'black')};
  &:hover {
    color: ${({ variant }) => (variant === 'alert'
    ? 'red'
    : variant === 'blue'
      ? COLORS.BLUE_SILKKE
      : 'black')};
  }
  & span {
    text-decoration: underline;
  }
  white-space: nowrap;
`;
export const SubMenu = styled.ul`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: center;
`;

export const SubListItem = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: center;

  padding: 2% 0;
`;

export const SubListComponent = styled.li`
  list-style: none;
`;

export const Delimiter = styled.div<ComponentProps>`
  width: 100%;
  height: 0.3px;
  opacity: ${({ size }) => (size?.isDesktop ? '0.5' : '1')};
  background: ${({ size }) => (size?.isDesktop ? 'rgba(201, 202, 202, 1)' : '#c0c3d6')};
`;
