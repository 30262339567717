/*   */
import COLORS from 'color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

export const ModalContainer = styled.div<ComponentProps>`
  max-height: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ModalOverlay = styled.div<ComponentProps>`
  position: fixed; // Fixe l'overlay par rapport à la fenêtre
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); // Couleur de fond semi-transparente
  z-index: 999; // Assure que l'overlay est en dessous du modal mais au-dessus d'autres éléments
`;
export const ModalContent = styled.div<ComponentProps>`

background-color: ${COLORS.GAINSBORO};
  border-radius: 5px;
  padding: 20px;
  width: ${({ size }) => (size?.isDesktop ? '30%' : '80%')};
  height: auto;
  display: flex;
  flex-direction: column;
  > div {
    margin 2% 0;
  }
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.BLUE_SILKKE};
  font-weight: bold;
`;

export const ModalHeader = styled.div<ComponentProps>`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const ModalBody = styled.div<ComponentProps>`
  font-size: 1rem;
  text-align: center;
  width: 100%;
`;

export const ModalFooter = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > button {
    margin: 0 2%;
  }
`;

export const ModalButton = styled.button<ComponentProps>`
  background-color: ${COLORS.BLUE_SILKKE};
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.BLUE_SILKKE};
    opacity: 0.8;
  }
`;
