/* init functional component */
import React, { useEffect } from 'react';
import navItems from 'component/navbar/navItems';
import useResponsive from 'hooks/useResponsive';
import { logout } from 'providers/auth';
import { useTranslation } from 'react-i18next';
import Notification from 'component/Notification';
import * as S from './HomeStyle';
import SilkkeLogo from '../../assets/img/Silkke_double_k_blue.png';
import WhiteSK from '../../assets/img/white_icon.png';

const Home: React.FC = () => {
  const screenSize = useResponsive();
  const buildStyleCompletedSuccessfully = new URLSearchParams(window.location.search).get('bsc');
  const isActive = (path: string) => {
    const currentPath = window.location.pathname;
    return currentPath === path;
  };
  useEffect(() => {
    document.title = 'silkke';
  }, []);
  const { t } = useTranslation();

  return (
    <S.Background size={screenSize}>
      {(screenSize.isMobile || screenSize.isTablet) && (
        <S.SilkkeLogo src={WhiteSK} size={screenSize} />
      )}
      <S.Menu size={screenSize}>

        {screenSize.isDesktop && (
          <S.SilkkeLogo src={SilkkeLogo} />
        )}
        <S.SubMenu>

          <S.SubListLink
            size={screenSize}
            to="/avatar/collection"
            dangerouslySetInnerHTML={{ __html: t('navbar.collection') || '' }}
          />
          <S.Delimiter size={screenSize} />
          <S.SubListLink
            size={screenSize}
            to="/movie"
            dangerouslySetInnerHTML={{ __html: t('navbar.movie') || '' }}
            variant=""
          />
          <S.Delimiter size={screenSize} />

          <S.SubListLink
            size={screenSize}
            to="/booking"
            dangerouslySetInnerHTML={{ __html: t('navbar.build') || '' }}
            variant="blue"
          />
          <S.Delimiter size={screenSize} />
          <S.SubListLink
            size={screenSize}
            to="/account/details"
            dangerouslySetInnerHTML={{ __html: t('navbar.account') || '' }}
          />
          <S.Delimiter size={screenSize} />
          <S.SubListLink
            size={screenSize}
            to="/logout"
            dangerouslySetInnerHTML={{ __html: t('navbar.logout') || '' }}
            variant="alert"
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              await logout();
              window.location.href = '/login';
            }}
          />
          {/* {navItems.map((section: Array<any>, index) => {
            const { length } = navItems;
            const isLast = index === length - 1;

            return (
              <SubList
                key={Math.random().toString(36).substring(2, 9)}
                // no bottom separator for last group item
                isLast={isLast}
              >
                {section.map((item: any, i: number) => {
                  const isSecond = i % 2 === 1;

                  return (
                    <S.SubListLink
                      size={screenSize}
                      key={t(item.name)}
                      to={item.path}
                      islogout={(item.path === '/logout' && 'true') || 'false'}
                      issecond={isSecond.toString()}
                      // can't use boolean value in react-router-dom Link
                      active={isActive(item.path).toString()}
                      dangerouslySetInnerHTML={{ __html: t(item.name) || '' }}
                      onClick={
                        item.path === '/logout'
                          ? async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (item.path === '/logout') {
                              await logout();
                              window.location.href = '/login';
                            }
                          }
                          : () => { }
                      }
                    />
                  );
                })}
              </SubList>
            );
          })} */}
        </S.SubMenu>
      </S.Menu>
      {buildStyleCompletedSuccessfully && <Notification type="success" message={t('avatar.style.success')} />}
    </S.Background>
  );
};

export default Home;
