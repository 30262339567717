import { request } from './request';

export const getUserDetails = async () => {
  const user = await request(true).get('/user/details');
  return user;
};

export const editUserDetails = async (data: any) => {
  const user = await request(true).patch('/user/details', data);
  return user;
};

export const editUserPassword = async (data: any) => {
  const user = await request(true).patch('/user/password', data);
  return user;
};

export const editUserLanguage = async (data: any) => {
  const user = await request(true).patch('/user/language', data);
  return user;
};
