import { PreRegisterFormData } from 'type/PreRegisterFormData';
import removeRedirectionsData from 'utils/removeRedirectionsData';
import { request } from './request';

export const PreRegistering = async ({
  firstName,
  lastName,
  email,
}: PreRegisterFormData) => {
  const registerAttempt = await request(false).put('/auth/register', {
    firstName,
    lastName,
    email,
  });

  return registerAttempt;
};

export const login = async ({ email, password }: any) => {
  const loginAttempt = await request(false).post('/auth/login', {
    email,
    password,
  });

  return loginAttempt;
};

export const logout = async () => {
  await request(true).post('/auth/logout');
  localStorage.removeItem('token');
  removeRedirectionsData();
};

export const checkIfUserExistByKey = async () => {
  const key = new URLSearchParams(window.location.search).get('key');
  const checkAttempt = await request(false).get(`/auth/check/${key}`);

  return checkAttempt;
};

export const completeRegister = async (data: any) => {
  const key = new URLSearchParams(window.location.search).get('key');
  const formData = { ...data, key };
  const registerAttempt = await request(false).patch(
    '/auth/register',
    formData,
  );
  return registerAttempt;
};

export const lostPassword = async (data: any) => {
  const lostAttempt = await request(false).post('/auth/password/lost', data);
  return lostAttempt;
};
export const resetPassword = async (data: any) => {
  const resetAttempt = await request(false).post('/auth/password/reset', data);
  return resetAttempt;
};

export const fastPassLogin = async (
  { email, firstName, lastName }: any,
  idCapsule: number,
) => {
  const loginAttempt = await request(false, {
    lang: localStorage.getItem('lang') || 'en',
  }).post('/auth/fastpass/login', {
    email,
    firstName,
    lastName,
    idCapsule,
  });

  return loginAttempt;
};
