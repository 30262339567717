type EyesColor = {
  [key: string]: string;
};

const eyesColor: EyesColor = {
  Brown: '#805933',
  Blue: '#2477b2',
  Grey: '#b3b3b3',
  Green: '#78b23e',
};

export default eyesColor;
