import i18n from 'i18n';

type Success = {
  [key: string]: string;
};

const sucessMessageList: Success = {
  resetPasswordSuccess: i18n.t('login.password.reset.success'),
  register: i18n.t('login.register.success'),
};

export default sucessMessageList;
