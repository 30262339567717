import Turnstile, { useTurnstile } from 'react-turnstile';
import React from 'react';
import { TurnstileTokenConfirm } from 'providers/turnstile';

function TurnstileWidget({ isValidSetter }: { isValidSetter: React.Dispatch<React.SetStateAction<boolean>> }) {
  const turnstile = useTurnstile();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '6%',
      }}
    >
      <Turnstile
        style={{

        }}
        theme="auto"
        sitekey="0x4AAAAAAAIbPjJGqfdd5nqT"
        onVerify={(token) => {
          TurnstileTokenConfirm({ token })
            .then((res) => {
              isValidSetter(true);
            })
            .catch((error) => {
              turnstile.reset();
              isValidSetter(false);
            });
        }}
      />
    </div>
  );
}

export default TurnstileWidget;
