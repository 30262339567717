import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'component/global/Header/Header';
import LoadingSpinner from 'component/LoadingSpinner';
import Footer from 'component/global/Footer/Footer';
import useResponsive from 'hooks/useResponsive';
import { getCapsuleLocationDetails } from 'providers/capsuleLocation';
import { useTranslation } from 'react-i18next';
import * as style from './BookingPaymentStyle';
import BookingPaymentForm from './BookingPaymentForm/BookingPaymentForm';
import BookingFree from './BookingFree/BookingFree';

const BookingPayment: React.FC = () => {
  const { t } = useTranslation();
  const { idCapsuleLocation } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [capsuleLocation, setCapsuleLocation] = React.useState<any>({});
  const screenSize = useResponsive();
  const date = new URLSearchParams(window.location.search).get('date');
  useEffect(() => {
    if (
      // eslint-disable-next-line radix
      (idCapsuleLocation && typeof parseInt(idCapsuleLocation) !== 'number')
      || !date
    ) {
      window.location.href = '/';
    }

    if (idCapsuleLocation) {
      getCapsuleLocationDetails(idCapsuleLocation).then((response) => {
        setCapsuleLocation(response.data.location);
        setLoading(false);
      });
    }
  }, []);
  return (
    <style.Container>
      <Header
        title="Book"
        subTitle="Capsule"
        desktopLinks={
          !new URLSearchParams(window.location.search).get('fastpass')
        }
      />
      {loading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <>
          <style.Main size={screenSize}>
            {capsuleLocation
            && capsuleLocation?.price
            && capsuleLocation.price !== '0.00' ? (
              <BookingPaymentForm capsule={capsuleLocation} />
              ) : (
                <BookingFree capsule={capsuleLocation} />
              )}
          </style.Main>
          <Footer
            title={t('book')}
            step={3}
            totalStep={4}
          />
        </>
      )}
    </style.Container>
  );
};

export default BookingPayment;
