/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import MarkerIcon from 'assets/img/marker-map.png';
/* import MarkerIcon from 'assets/img/silkke_marker.png'; */
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import COLORS from 'color';
import * as style from './MapStyle';

type MapCoordinates = [number, number];
const Map = ({
  position,
  markers,
  css = {},
}: {
  position: MapCoordinates;
  markers: Array<any>;
  css?: any;
}) => {
  const screen = window.screen.width;
  const markerIcon = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
  });
  useEffect(() => { }, [markers]);
  const { t } = useTranslation();
  const screenSize = useResponsive();
  return (
    <MapContainer
      /* when key change the map is re-render this is how react leaflet work */
      key={`${position[0]}-${position[1]} ${screen}`}
      zoom={8}
      center={position}
      style={{ height: '50vh', width: '100%', ...css }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution={`&copy; <a href="https://www.silkke.com/" target="_blank" style="color:${COLORS.BLUE_SILKKE};">silkke</a>`}
      />

      {
        markers.length > 0
        && markers.map((marker, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position={[
              parseFloat(marker.location.latitude),
              parseFloat(marker.location.longitude),
            ]}
            icon={markerIcon}
          >
            <Popup>
              <style.PopupContainer>
                <style.PopupTitle>{marker.libel}</style.PopupTitle>
                <style.PopupContent>
                  <p>{marker.location.address}</p>
                  <p>
                    {' '}
                    {marker.location.zipCode}
                    {' '}
                    {marker.location.city}
                  </p>
                </style.PopupContent>
                <style.PopupButton
                  size={screenSize}
                  to={`/booking/new/${marker.idCapsuleLocation}`}
                >
                  {t('booking')}
                </style.PopupButton>
              </style.PopupContainer>
            </Popup>
          </Marker>
        ))
      }
    </MapContainer>
  );
};

export default Map;
