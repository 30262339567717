export default {
  'complete.register.address': 'Adresse (Rue)',
  'complete.register.birthdate': 'Date de naissance',
  'complete.register.chooseCountryError': 'Vous devez choisir un pays',
  'register.firstName.error': 'Le prenom est requis',
  'complete.register.city': 'Ville',
  'complete.register.complement': "Complément d'adresse",
  'complete.register.confirmPassword': 'Confirmer le mot de passe',
  'complete.register.country': 'Pays',
  'complete.register.keyEmpty': 'La key est vide',
  'complete.register.keyMissing': 'La key est manquante',
  'complete.register.password': 'Mot de passe',
  'complete.register.passwordNotMatch':
    'Les mots de passe ne correspondent pas',
  'complete.register.phone': 'Téléphone',
  'complete.register.postalCode': 'Code postal',
  'complete.register.save': 'Enregistrer',
  'complete.register.title': 'Compte',
  'login.email.error': "L'adresse eMail est requise",
  'login.error.credentials': 'Identifiants incorrects',
  'login.join': 'Rejoignez-nous',
  'login.mail': 'Adresse eMail',
  'login.password': 'Mot de passe',
  'login.password.error': 'Le mot de passe est requis',
  'login.password.forgot': 'Mot de passe oublié ?',
  'login.password.lost.mail.success': 'Nous venons de vous envoyer un eMail',
  'login.password.reset.success': 'Votre mot de passe a été réinitialisé',
  'login.register.success':
    'Votre compte a été créé, vous pouvez vous connecter',
  'login.submit': 'Se connecter',
  'login.title': 'Connexion',
  'register.already': 'Déjà inscrit ?',
  'register.email.alreadyUsed': 'Cette adresse eMail est déjà utilisée',
  'register.email.error': "L'adresse eMail est requise",
  'register.firstname': 'Prénom',
  'register.firstname.error': 'Le prénom est requis',
  'register.lastname': 'Nom',
  'register.lastName.error': 'Le nom est requis',
  'register.mail': 'Adresse eMail',
  'register.pre.header': 'Inscription',
  'register.submit': "S'inscrire",
  'register.success':
    'Nous venons de vous envoyer un eMail pour confirmer votre compte',
  'register.terms':
    "J'accepte les <a href='https://www.silkke.com/terms' target='_blank'>conditions d'utilisation</a> ",
  'register.terms.error': "Vous devez accepter les conditions d'utilisation",
  'register.title': 'Créer un compte',
  'reset.password.button': 'Réinitialiser',
  'reset.password.title': 'Réinitialiser votre mot de passe',
  isRequired: 'requis',
  'login.invalidCapsuleLocation': 'idCapsuleLocation invalide',
  'login.capsuleNotAvailable': 'Capsule non disponible',
};
