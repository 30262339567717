export default {
  'digital-human.create.preview': 'créons votre nouveau digital Human',
  'digital-human.create.preview.next': 'Suivant',
  'digital-human.create.preview.footer': 'créer votre digital human',
  'avatar.style.warning':
    ' Vous ne pourrez plus changer de style pour cet avatar !',
  'avatar.style.confirm': 'Confirmer ce style ?',
  'avatar.style.confirm.info':
    "Une vidéo va être générée avec le style que vous venez d'éditer.",
  'avatar.style.success':
    'Votre style est en cours de création, vous recevrez un email quand il sera disponible',
};
