import useResponsive from 'hooks/useResponsive';
import React from 'react';
import { Link } from 'react-router-dom';
import navItems from 'component/navbar/navItems';
import COLORS from 'color';
import { logout } from 'providers/auth';
import { useTranslation } from 'react-i18next';
import * as header from './HeaderStyle';

export const Header: React.FC<{
  title: string;
  subTitle: string;
  // eslint-disable-next-line react/require-default-props
  desktopLinks?: boolean;
}> = ({ title, subTitle, desktopLinks = true }) => {
  const screenSize = useResponsive();
  const { t } = useTranslation();
  const forbiddenPaths = [
    '/login',
    '/register',
    '/logout',
    '/terms',
    '/',
    '/auth/complete-registration',
    '/auth/reset-password',
  ];
  const isAllowed = !forbiddenPaths.includes(window.location.pathname);
  return (
    <header.Container>
      <header.LeftBlock size={screenSize}>
        <header.InnerLeftBlock size={screenSize}>
          {desktopLinks ? (
            <Link to="/">{title}</Link>
          ) : (
            <span>{title}</span>
          )}
          <h3>{subTitle}</h3>
        </header.InnerLeftBlock>
      </header.LeftBlock>
      {screenSize.isDesktop && isAllowed && desktopLinks && (
        <header.RightBlock size={screenSize}>
          <header.RightBlockList>
            {navItems.map((items) => items.map((item) => {
              const randomkey = Math.random() * 1000;
              const isActive = window.location.pathname === item.path;
              return (
                <li key={randomkey}>
                  <Link
                    to={item.path}
                    dangerouslySetInnerHTML={{ __html: t(item.name) || '' }}
                    style={{
                      color:
                        item.path === '/logout'
                          ? COLORS.RED_LIGHT
                          : COLORS.BLUE_SILKKE,
                      fontWeight: isActive ? 'bold' : 'normal',
                    }}
                    onClick={
                      item.path === '/logout'
                        ? async (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (item.path === '/logout') {
                            await logout();
                            window.location.href = '/login';
                          }
                        }
                        : () => { }
                    }
                  />
                </li>
              );
            }))}
          </header.RightBlockList>
        </header.RightBlock>
      )}
    </header.Container>
  );
};

export default Header;
