import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import { useForm } from 'react-hook-form';
import { fastPassLogin } from 'providers/auth';
import LoadingSpinner from 'component/LoadingSpinner';
import Notification from 'component/Notification';
import Button from 'component/Button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as style from './FastPassStyle';
import silkkeLogo from '../../../assets/img/Silkke_double_k_blue.png';

const FastPassLogin: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  const screenSize = useResponsive();
  const email = t('register.mail');
  const firstName = t('register.firstname');
  const lastName = t('register.lastname');

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { idCapsule } = useParams<{ idCapsule: string }>();

  useEffect(() => {
    document.title = t('title.fastPass');

    if (!idCapsule || Number.isNaN(parseInt(idCapsule, 10))) {
      setError(t('login.capsuleNotAvailable'));
    }
  }, [idCapsule]);

  const submitLogin = (formData: any) => {
    setLoading(true);
    if (idCapsule) {
      if (!Number.isNaN(parseInt(idCapsule, 10))) {
        fastPassLogin({ ...formData }, parseInt(idCapsule, 10))
          .then((response) => {
            const { token, lang, idCapsuleLocation } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('lang', lang);
            const today = new Date().toISOString().slice(0, 10);
            window.location.href = `/booking/pay/${idCapsuleLocation}?date=${today}&fastpass=true`;
          })
          .catch((err) => {
            const { data } = err.response;
            if (data.code === 404 || data.code === 103) {
              setError(t('login.capsuleNotAvailable'));
            } else {
              setError(t(data.message));
            }
            setLoading(false);
          });
      } else {
        setError(t('login.capsuleNotAvailable'));
        setLoading(false);
      }
    }
  };

  const randomKeyString = Math.random().toString(36).substring(7);
  return (
    <style.Background size={screenSize}>
      <style.Form size={screenSize} onSubmit={handleSubmit(submitLogin)}>
        <style.FormHeader size={screenSize}>
          <style.SilkkeLogo src={silkkeLogo} />
          <style.FormHeaderTitle>{t('register.title')}</style.FormHeaderTitle>
        </style.FormHeader>

        <style.FormGroupInput>
          <style.FormInput
            {...register('firstName', { required: true })}
            placeholder={firstName}
          />

          {errors.firstName && (
            <style.FormError size={screenSize}>
              {t('register.firstName.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>
        <style.FormGroupInput>
          <style.FormInput
            {...register('lastName', { required: true })}
            placeholder={lastName}
          />

          {errors.lastName && (
            <style.FormError size={screenSize}>
              {t('register.lastName.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>

        <style.FormGroupInput>
          <style.FormInput
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            placeholder={email}
          />

          {errors.email && (
            <style.FormError size={screenSize}>
              {t('login.email.error')}
            </style.FormError>
          )}
        </style.FormGroupInput>

        <style.FormBottom>
          {loading ? (
            <LoadingSpinner style={{ marginTop: '10%' }} />
          ) : (
            <Button
              width={screenSize.isTablet ? '60%' : '80%'}
              height="2.5rem"
              style={{ marginTop: '4%' }}
              disabled={false}
            >
              {t('login.submit')}
            </Button>
          )}
        </style.FormBottom>
      </style.Form>

      {error && <Notification type="error" message={error} key={randomKeyString} />}
    </style.Background>
  );
};

export default FastPassLogin;
