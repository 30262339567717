import styled from 'styled-components';
import COLORS from 'color';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  height: 100%;

  background-color: ${COLORS.GAINSBORO};
`;

Container.displayName = 'ContainerRegister';

export const Main = styled.main`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 79.6%;
  justify-content: center;
  max-width: 100%;
`;

Container.displayName = 'MainRegister';

export const Form = styled.form<ComponentProps>`
  margin: 0 auto;
  min-width: ${({ size }) => (size?.isDesktop ? '50%' : '95%')};
  max-width: ${({ size }) => (size?.isDesktop ? '50%' : '95%')};
  min-height: 90%;
  max-height: 90%;
  grid-template-columns: ${({ size }) => (size?.isDesktop ? '1fr 1fr' : '1fr')};
`;

Container.displayName = 'FormRegister';

export const FormInput = styled.input<ComponentProps>`
  padding: 2% 0 2% 1%;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  transition: all 0.2s ease-in-out;
  display:block;
  color: background: rgba(171, 172, 172, 1);
  background-color: ${COLORS.GAINSBORO};
  margin: 0 auto;
  min-width: 100%;
  max-width: 100%;
`;

FormInput.displayName = 'FormInput';

/* FormSelect must same design than forminput */

export const FormSelect = styled.select<ComponentProps>`

  padding: 2% 0 2% 1%;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  transition: all 0.2s ease-in-out;
  display:block;
  color: background: rgba(171, 172, 172, 1);
  background-color: ${COLORS.GAINSBORO};
  margin: 0 auto;
  min-width: 100%;
  max-width: 100%;
`;
