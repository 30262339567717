import styled from 'styled-components';
import COLORS from 'color';
import { ComponentProps } from 'type/ComponentProps';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: ${COLORS.GAINSBORO};
  height: 100%;
  min-width: 100%;
  max-width: 100%;
`;
Container.displayName = 'ContainerDetails';

export const NavSettings = styled.ul<ComponentProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  min-width: 40%;
  max-width: ${({ size }) => (size?.isDesktop ? '75%' : '90%')};
  min-height: 10%;
  border: 1px solid black;
  > li {
    list-style: none;
    text-align: center;
    margin: 0 auto;
    > 
    }
  }
`;
export const NavItem = styled(Link)<ComponentProps & { selected?: boolean }>`
  text-decoration: none;
  padding: ${({ size }) => (size?.isDesktop ? '2%' : '5%')} 0;
  border: 1px solid rgba(188, 189, 189, 1);
  font-family: 'Urbanist', sans-serif;
  border-radius: 5px;
  /* put in the right of parent div */
  margin-left: -1px;
  background: ${({ selected }) => (selected ? 'rgba(69, 78, 206, 1)' : COLORS.GAINSBORO)}};
  display: block;
  color: rgba(188, 189, 189, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: ${({ size }) => (size?.isDesktop ? '30%' : size?.isTablet ? '80%' : '90%')};
  text-align: center;
  margin: 0 auto;
`;
export const ViewSettings = styled.div`
  height: 79%;
`;
