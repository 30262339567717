import React, { useEffect, useState } from 'react';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { set, useForm } from 'react-hook-form';
import LoadingSpinner from 'component/LoadingSpinner';
import { createPaidBooking } from 'providers/booking';
import Notification from 'component/Notification';
import * as style from './BookingPaymentFormStyle';

const BookingPaymentForm: React.FC<{ capsule: any }> = ({ capsule }) => {
  const screenSize = useResponsive();
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('title.payment');
  }, []);
  const date = new URLSearchParams(window.location.search).get('date');
  /* init react hook form */
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const priceFormatted = parseFloat(capsule.price).toFixed(2);
  const formattedPrice = priceFormatted.endsWith('.00')
    ? priceFormatted.slice(0, -3)
    : priceFormatted;
  const randomKeyString = Math.random().toString(36).substring(7);
  const submit = (data: any) => {
    setLoading(true);
    if (errors.coupon) {
      setErrorMessage(t('booking.payment.coupon.required'));
    }

    if (date) {
      createPaidBooking(capsule.idCapsuleLocation, date, data.coupon)
        .then((response) => {
          if (response.data.success) {
            const fastpass = new URLSearchParams(window.location.search).get(
              'fastpass',
            );
            const href = `/booking/${response.data.booking.idBooking}${fastpass === 'true' ? '?fastpass=true' : ''
            }`;
            window.location.href = href;
          }
        })
        .catch((error) => {
          setErrorMessage(t(error.response.data.message));
          setLoading(false);
        });
    }
  };

  return (
    <style.Container>
      <style.Form size={screenSize}>
        <style.Title>
          {formattedPrice}
          {' '}
          euros
        </style.Title>

        <style.FormGroupInput size={screenSize}>
          <style.FormInput
            size={screenSize}
            {...register('coupon', { required: true })}
            placeholder={t('booking.payment.coupon')}
          />
        </style.FormGroupInput>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <style.FormButton onClick={handleSubmit(submit)}>
            {t('booking.payment.submit')}
          </style.FormButton>
        )}
      </style.Form>
      {errorMessage && <Notification key={randomKeyString} message={errorMessage} type="error" />}
    </style.Container>
  );
};

export default BookingPaymentForm;
