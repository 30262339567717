import { useState, useEffect } from 'react';

type HairColors = {
  meshColor: number;
  setMeshColor: React.Dispatch<React.SetStateAction<number>>;
  endColor: number;
  setEndColor: React.Dispatch<React.SetStateAction<number>>;
  hairColor: number;
  setHairColor: React.Dispatch<React.SetStateAction<number>>;
};

export const useHairColors = (hairsColors: any): HairColors => {
  const [meshColor, setMeshColor] = useState(0);
  const [endColor, setEndColor] = useState(0);
  const [hairColor, setHairColor] = useState(0);

  useEffect(() => {
    if (hairsColors?.mesh?.colors) {
      setMeshColor(hairsColors.mesh.colors[0]?.idStyleAsset);
    }
    if (hairsColors?.end?.colors) {
      setEndColor(hairsColors.end.colors[0]?.idStyleAsset);
    }
    if (hairsColors?.hair?.colors) {
      setHairColor(hairsColors.hair.colors[0]?.idStyleAsset);
    }
  }, [
    hairsColors?.mesh?.colors,
    hairsColors?.end?.colors,
    hairsColors?.hair?.colors,
  ]);
  return {
    meshColor,
    setMeshColor,
    endColor,
    setEndColor,
    hairColor,
    setHairColor,
  };
};
